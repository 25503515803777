import { AxiosInstance } from "@bps/http-client";
import { BpIdUsersSearchArgs } from "../../bp-id/bp-id.dtos";

import { DateTime } from "@bps/utils";
import {
  ConsumerUser,
  ConsumerUserDto,
  ConsumerUserProfile,
  ConsumerUserProfileDto,
  MobileAppSettings,
  ResultsResponse
} from "./best-health-account-ops-gateway.dtos";
import { IBestHealthAccountOpsGateway } from "./best-health-account-ops-gateway.interface";
import { convertIdentityDto } from "./best-health-account-ops-gateway.utils";

export class BestHealthAccountOpsGateway
  implements IBestHealthAccountOpsGateway {
  constructor(private api: AxiosInstance) {}

  public async postProfileSearch(
    searchText: string
  ): Promise<ResultsResponse<ConsumerUserProfile>> {
    const { data } = await this.api.post<
      ResultsResponse<ConsumerUserProfileDto>
    >("/user/profile/search", undefined, {
      params: {
        searchText
      }
    });

    return {
      ...data,
      records:
        data?.records?.map(
          ({
            dateOfBirth,
            updatedOn,
            privacyPolicyAgreementDate,
            devices,
            dependants,
            ...rest
          }) => ({
            ...rest,
            dependants: dependants?.map(({ dOB, ...rest }) => ({
              ...rest,
              dOB: dOB ? DateTime.fromISO(dOB) : undefined
            })),
            devices: devices?.map(({ lastSeenDateTimeUtc, ...rest }) => ({
              ...rest,
              lastSeenDateTimeUtc: lastSeenDateTimeUtc
                ? DateTime.fromISO(lastSeenDateTimeUtc)
                : undefined
            })),
            dateOfBirth: dateOfBirth
              ? DateTime.fromISO(dateOfBirth)
              : undefined,
            updatedOn: updatedOn ? DateTime.fromISO(updatedOn) : undefined,
            privacyPolicyAgreementDate: updatedOn
              ? DateTime.fromISO(privacyPolicyAgreementDate)
              : undefined
          })
        ) || []
    };
  }

  public async getProfile(id: string): Promise<ConsumerUserProfile> {
    const { data } = await this.api.get<ConsumerUserProfileDto>(
      `/user/profile/${id}`
    );
    return {
      ...data,
      dependants: data.dependants?.map(({ dOB, ...rest }) => ({
        ...rest,
        dOB: dOB ? DateTime.fromISO(dOB) : undefined
      })),
      devices: data.devices?.map(({ lastSeenDateTimeUtc, ...rest }) => ({
        ...rest,
        lastSeenDateTimeUtc: lastSeenDateTimeUtc
          ? DateTime.fromISO(lastSeenDateTimeUtc)
          : undefined
      })),
      dateOfBirth: data.dateOfBirth
        ? DateTime.fromISO(data.dateOfBirth)
        : undefined,
      updatedOn: data.updatedOn ? DateTime.fromISO(data.updatedOn) : undefined,
      privacyPolicyAgreementDate: data.updatedOn
        ? DateTime.fromISO(data.privacyPolicyAgreementDate)
        : undefined
    };
  }

  public async getIdentity(userName: string): Promise<ConsumerUser> {
    const { data } = await this.api.get<ConsumerUserDto>(
      `/user/identity/${userName}`
    );
    return convertIdentityDto(data);
  }

  public async deleteUser(id: string): Promise<any> {
    const { data } = await this.api.delete(`/user/${id}`);
    return data;
  }

  public async postIdentitySearch(
    values: BpIdUsersSearchArgs
  ): Promise<ResultsResponse<ConsumerUser>> {
    const { data } = await this.api.post<ResultsResponse<ConsumerUserDto>>(
      "/user/identity/search",
      values
    );
    return {
      ...data,
      records: data?.records?.map(convertIdentityDto) || []
    };
  }

  public async getMobileAppSettings(): Promise<MobileAppSettings[]> {
    const { data } = await this.api.get<MobileAppSettings[]>(
      "bha/MobileAppSettings/"
    );
    return data;
  }

  public async putMobileAppSettings(
    updates: MobileAppSettings
  ): Promise<MobileAppSettings> {
    const { data } = await this.api.put<MobileAppSettings>(
      "bha/MobileAppSettings/",
      updates
    );
    return data;
  }

  public async postMobileAppSettings(
    updates: MobileAppSettings
  ): Promise<MobileAppSettings> {
    const { data } = await this.api.post<MobileAppSettings>(
      "bha/MobileAppSettings/",
      updates
    );
    return data;
  }
}

import { useRootStore } from "@stores/StoresProvider";
import { cacheUpsertSingleItemInArray } from "@libs/react-query/react-query-cache.utils";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ApplicationType, Tenant } from "../plt/plt-gateway.dtos";
import {
  CustomerAccount,
  CustomerAccountApplication,
  CustomerAccountSearchArgs,
  CustomerAccountUser,
  CustomerAccountUserCreateArgs,
  ServiceAgreementDto,
  ServiceAgreementsArgs
} from "./cam-gateway.dtos";
import { HttpError } from "@bps/http-client";
import { PltCacheKeys } from "../plt/plt-gateway.hooks";
import { useGateways } from "@libs/api/gateways-context";

export const CamCacheKeys = {
  CustomerAccounts: "customer-accounts",
  CustomerAccount: "customer-account",
  CustomerAccountUsers: "env-users",
  CustomerAccountAllUsers: "customer-users-all",
  CustomerAccountApplications: "env-applications",
  CustomerServiceAgreements: "env-service-agreements"
};

export const useCustomerAccountsQuery = (args: CustomerAccountSearchArgs) => {
  const { camGateway, platformGateway } = useGateways();

  return useQuery<CustomerAccount[], HttpError>(
    [CamCacheKeys.CustomerAccounts, args],
    async () => {
      if ((args.crmId.replace(/\D+/g, "").length ?? 0) < 2) {
        return [];
      } else {
        const customerAccounts = await camGateway.getCustomerAccounts(args);

        return Promise.all(
          customerAccounts.map(async customerAccount => {
            const tenants = await platformGateway.getTenantsByCrmId(
              customerAccount.crmId
            );
            return {
              ...customerAccount,
              tenants,
              caTenants: tenants.filter(
                t => t.application === ApplicationType.CAM
              )
            };
          })
        );
      }
    }
  );
};

export const useCustomerAccountQuery = (crmId: string) => {
  const { camGateway, platformGateway } = useGateways();

  return useQuery<CustomerAccount | undefined>(
    [CamCacheKeys.CustomerAccount, crmId],
    async () => {
      const [customerAccount, tenants] = await Promise.all([
        camGateway.getCustomerAccount(crmId),
        platformGateway.getTenantsByCrmId(crmId)
      ]);

      return {
        ...customerAccount,
        tenants,
        caTenants: tenants.filter(t => t.application === ApplicationType.CAM)
      };
    }
  );
};

export const useCustomerAccountCreateMutation = (crmId: string) => {
  const { camGateway } = useGateways();

  const queryClient = useQueryClient();

  return useMutation<
    CustomerAccount,
    unknown,
    { crmId: string; name?: string }
  >(({ crmId, name }) => camGateway.createCustomerAccount(crmId, name), {
    onSuccess: async () => {
      queryClient.invalidateQueries([CamCacheKeys.CustomerAccount, crmId]);
      queryClient.invalidateQueries([
        CamCacheKeys.CustomerAccountApplications,
        crmId
      ]);
    }
  });
};

export const useCreateCustomerAccountUser = () => {
  const { camGateway } = useGateways();

  const queryClient = useQueryClient();

  return useMutation<
    CustomerAccountUser,
    HttpError,
    CustomerAccountUserCreateArgs
  >(camGateway.createCustomerAccountUser, {
    onSuccess: async dto => {
      cacheUpsertSingleItemInArray({
        queryClient,
        queryKey: CamCacheKeys.CustomerAccountAllUsers,
        item: dto,
        asFirstItem: true
      });
    }
  });
};

export const useCustomerAccountUsersQuery = (crmId: string) => {
  const { camGateway } = useGateways();

  return useQuery<CustomerAccountUser[]>(
    [CamCacheKeys.CustomerAccountUsers, crmId],
    async () => {
      return await camGateway.getCustomerAccountUsers(crmId);
    }
  );
};

export const useCustomerAccountAllUsersQuery = (crmId: string) => {
  const { camGateway } = useGateways();

  return useQuery<CustomerAccountUser[]>(
    [CamCacheKeys.CustomerAccountAllUsers, crmId],
    async () => {
      return await camGateway.getAllCustomerAccountUsers(crmId);
    }
  );
};

export const useCustomerAccountApplicationsQuery = (crmId: string) => {
  const { platformGateway } = useGateways();

  return useQuery<CustomerAccountApplication[]>(
    [CamCacheKeys.CustomerAccountApplications, crmId],
    async () => {
      const tenants = await platformGateway.getTenantsByCrmId(crmId);

      return tenants.map(tenant => {
        const customerAccountTenant = tenants.find(
          customerAccountTenant =>
            customerAccountTenant.id === tenant.customerTenantId
        );

        return {
          crmId: tenant.crmId,
          tenantId: tenant.id,
          tenantName: tenant.name,
          appType: tenant.application,
          customerAccountTenantId: customerAccountTenant?.id,
          customerAccountTenantName: customerAccountTenant?.name
        };
      });
    }
  );
};

export const useServiceAgreements = (args: ServiceAgreementsArgs) => {
  const { camGateway } = useGateways();

  return useQuery<ServiceAgreementDto[], HttpError>(
    [CamCacheKeys.CustomerServiceAgreements, args],
    () => camGateway.getServiceAgreements(args)
  );
};

export const useCustomerAccountReset = (tenantId: string, crmId: string) => {
  const { camGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<Tenant, unknown>(
    () => camGateway.resetCustomerAccount(tenantId),
    {
      onSuccess: async () => {
        feedback.success("Stripe Customer Account reset successfully!");
        queryClient.invalidateQueries([
          PltCacheKeys.TenantStripeCustomer,
          tenantId
        ]);
        queryClient.invalidateQueries([PltCacheKeys.Tenants, tenantId]);
        queryClient.invalidateQueries([CamCacheKeys.CustomerAccount, crmId]);
        queryClient.invalidateQueries([
          CamCacheKeys.CustomerAccountApplications,
          crmId
        ]);
      },
      onError: () => {
        feedback.error(
          "Error occurred while resetting the Stripe Customer Account!"
        );
      }
    }
  );
};

import {
  CombinedSoftwarePackage,
  FieldDeviceDateRangeSoftwarePackageVersionConfig,
  FieldDeviceIdentifier,
  FieldDeviceIdentifierDto,
  FieldDeviceReportedConfig,
  SoftwarePackageServiceCodes
} from "@libs/api/gateways/field/field-ops-gateway.dtos";
import { DateTime } from "@bps/utils";

export const mapFieldDeviceIdentifierDtoToIdentifier = (
  identifier: FieldDeviceIdentifierDto
) => {
  return {
    ...identifier,
    lastObservedUtc: identifier.lastObservedUtc
      ? DateTime.fromISO(identifier.lastObservedUtc)
      : undefined,
    prevObservedUtc: identifier.prevObservedUtc
      ? DateTime.fromISO(identifier.prevObservedUtc)
      : undefined,
    firstObservedUtc: identifier.firstObservedUtc
      ? DateTime.fromISO(identifier.firstObservedUtc)
      : undefined
  } as FieldDeviceIdentifier;
};
export const shorthandServiceName = (
  softwarePackage: CombinedSoftwarePackage
) => {
  switch (softwarePackage.softwarePackageCode) {
    case SoftwarePackageServiceCodes.FieldController:
      return "Controller";
    case SoftwarePackageServiceCodes.RecoveryAgent:
      return "Recovery Agent";
    default:
      return softwarePackage.name;
  }
};
export const combinedSoftwarePackages = (
  desiredSoftwarePackage: FieldDeviceDateRangeSoftwarePackageVersionConfig[],
  reportedSoftwarePackage: FieldDeviceReportedConfig[]
) => {
  const combined = [] as CombinedSoftwarePackage[];

  // Look over desired modules and push
  desiredSoftwarePackage.forEach(desired => {
    combined.push({
      desiredVersion: desired.version,
      currentVersion: reportedSoftwarePackage.find(
        sp => sp.softwarePackageCode === desired.code
      )?.softwarePackageVersion,
      id: desired.id,
      softwarePackageCode: desired.code,
      softwarePackageType: desired.type!,
      name: desired.name
    });
  });

  // Look over reported modules and push if not already added.
  reportedSoftwarePackage.forEach(reported => {
    !combined.some(
      sp => sp.softwarePackageCode === reported.softwarePackageCode
    ) &&
      combined.push({
        desiredVersion: desiredSoftwarePackage.find(
          sp => sp.code === reported.softwarePackageCode
        )?.version,
        currentVersion: reported.softwarePackageVersion,
        id: reported.softwarePackageId,
        softwarePackageCode: reported.softwarePackageCode,
        softwarePackageType: reported.softwarePackageType,
        name: reported.softwarePackageDisplayName
      });
  });

  return combined;
};

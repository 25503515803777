import { BpIdUsersSearchArgs } from "@libs/api/gateways/bp-id/bp-id.dtos";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ConsumerUser,
  ConsumerUserProfile,
  DeleteUserMutationArgs,
  MobileAppSettings,
  ResultsResponse
} from "@libs/api/gateways/best-health/account/best-health-account-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

const BestHealthAccountCacheKeys = {
  BhaProfile: "bha-full-profile",
  BhaProfiles: "bha-profiles",
  MobileAppSettings: "bha-mobile-app-settings",
  BhaIdentities: "bha-identities"
};

export const useCUIdentitySearch = (values?: BpIdUsersSearchArgs) => {
  const { bestHealthAccountOpsGateway } = useGateways();
  return useQuery<ResultsResponse<ConsumerUser>>(
    [BestHealthAccountCacheKeys.BhaProfiles, values],
    async () => {
      return values
        ? await bestHealthAccountOpsGateway.postIdentitySearch(values)
        : { records: [], tooManyResults: false };
    },
    {
      enabled: Boolean(values)
    }
  );
};

export const useBhBannerMutation = () => {
  // @TODO Replace when api ready
  return useMutation<string, Error, string>(() => Promise.resolve(""));
};

export const useGetMobileAppSettings = () => {
  const { bestHealthAccountOpsGateway } = useGateways();

  return useQuery<MobileAppSettings[]>(
    [BestHealthAccountCacheKeys.MobileAppSettings],
    async () => {
      return await bestHealthAccountOpsGateway.getMobileAppSettings();
    }
  );
};

export const usePutMobileAppSettings = () => {
  const { bestHealthAccountOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<MobileAppSettings, Error, MobileAppSettings>(
    updates => bestHealthAccountOpsGateway.putMobileAppSettings(updates),
    {
      onSuccess: async (response, updates) => {
        queryClient.setQueriesData<MobileAppSettings[]>(
          [BestHealthAccountCacheKeys.MobileAppSettings],
          (data: MobileAppSettings[]) => {
            const newData = [...data];
            const i = newData.findIndex(
              setting =>
                setting.mobileAppSettingsId === updates.mobileAppSettingsId
            );
            if (i && newData[i]) {
              newData[i] = {
                ...newData[i],
                ...response
              };
            }

            return newData;
          }
        );
      },
      onError: e => {
        throw e;
      }
    }
  );
};

export const usePostMobileAppSettings = () => {
  const { bestHealthAccountOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<MobileAppSettings, Error, MobileAppSettings>(
    updates => bestHealthAccountOpsGateway.postMobileAppSettings(updates),
    {
      onSuccess: async (response, updates) => {
        queryClient.setQueriesData<MobileAppSettings[]>(
          [BestHealthAccountCacheKeys.MobileAppSettings],
          (data: MobileAppSettings[]) => {
            const newData = [...data];
            const i = newData.findIndex(
              setting =>
                setting.mobileAppSettingsId === updates.mobileAppSettingsId
            );
            if (i && newData[i]) {
              newData[i] = {
                ...newData[i],
                ...response
              };
            }

            return newData;
          }
        );
      },
      onError: e => {
        throw e;
      }
    }
  );
};

export const useDeleteUserMutation = () => {
  const { bestHealthAccountOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<string, Error, DeleteUserMutationArgs>(
    ({ id }) => bestHealthAccountOpsGateway.deleteUser(id),
    {
      onSuccess: async (response: string, { id }: DeleteUserMutationArgs) => {
        queryClient.setQueriesData<ConsumerUserProfile[]>(
          [BestHealthAccountCacheKeys.BhaProfiles],
          (data: ConsumerUserProfile[]) => {
            return data.filter(profile => profile.id !== id);
          }
        );
      }
    }
  );
};

export const useConsumerUserProfileSearch = (search: string) => {
  const { bestHealthAccountOpsGateway } = useGateways();
  return useQuery<ResultsResponse<ConsumerUserProfile>>(
    [BestHealthAccountCacheKeys.BhaProfiles, search],
    async () => {
      return await bestHealthAccountOpsGateway.postProfileSearch(search);
    },
    {
      enabled: Boolean(search)
    }
  );
};

export const useConsumerUser = (userName: string) => {
  const { bestHealthAccountOpsGateway } = useGateways();
  return useQuery<ConsumerUser>(
    [BestHealthAccountCacheKeys.BhaIdentities, userName],
    async () => {
      return await bestHealthAccountOpsGateway.getIdentity(userName);
    },
    {
      enabled: Boolean(userName)
    }
  );
};

export const useBhaProfile = (id: string) => {
  const { bestHealthAccountOpsGateway } = useGateways();
  return useQuery<ConsumerUserProfile>(
    [BestHealthAccountCacheKeys.BhaProfile, id],
    async () => {
      return await bestHealthAccountOpsGateway.getProfile(id);
    },
    {
      enabled: Boolean(id)
    }
  );
};

import { DateTime } from "@bps/utils";
import { ChangeLogDto } from "@libs/api/types/common-dtos";

export type AttendeeType = "PATIENT" | "USER" | "CONTACT";

export enum AppointmentStatusCode {
  Booked = "BO",
  Waiting = "W",
  WithProvider = "WP",
  Finalised = "BILL",
  Completed = "CPL",
  DidNotAttend = "NA",
  UrgentBooked = "UBO",
  UrgentWaiting = "UW"
}

export enum CalendarEventStatus {
  Confirmed = "CONFIRMED",
  Cancelled = "CANCELLED"
}

export interface BookingTenantSettings {
  appointmentConfirmationSettings?: AppointmentConfirmationSettings;
  eTag: string;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
}

export type AppointmentReminderJob = {
  id: string;
  orgUnitId: string;
  jobName: string;
  jobSchedule: AppointmentReminderJobSchedule;
  appointmentReminderOptions: AppointmentReminderJobOptions;
  status: string;
  eTag: string;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
};

export type AppointmentReminderJobRun = {
  id: string;
  jobId: string;
  calendarEventCount?: number;
  eTag: string;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
};

export type AppointmentReminderJobOptions = {
  appointmentTypes: string[];
  sendBefore: number;
  templateId: string;
};

export interface AppointmentReminderJobSchedule {
  daysOfWeek: number[];
  hour: number;
  minute: number;
}

export enum BookedByTypeEnum {
  onlineBooking = "OLB",
  user = "USR"
}

export enum CalendarEventType {
  Appointment = "APPT",
  Meeting = "MEETING",
  Unavailable = "UNAVAILABLE",
  TemporaryReservation = "TEMPRESERVE"
}

//can make type strong
export interface AppointmentConfirmationSettings {
  isActive: boolean;
}

export interface AppointmentReminderJobDto
  extends Omit<AppointmentReminderJob, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface AppointmentReminderJobRunDto
  extends Omit<AppointmentReminderJobRun, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface BookingTenantSettingsDto
  extends Omit<BookingTenantSettings, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface CalendarEventAttendeeDto {
  type: AttendeeType;
  attendeeId: string;
  attendeeStatus?: AppointmentStatusCode;
  cancellationReasonId?: string;
  cancellationText?: string;
  status?: CalendarEventStatus;
  cancellationDateTime?: string;
}

export interface CalendarEventDto {
  id: string;
  startTime: string;
  endTime: string;
  type: CalendarEventType;
  attendees: CalendarEventAttendeeDto[];
  isPartOfRecurrence?: boolean;
  changeLog?: ChangeLogDto;
  orgUnitId: string;
  appointmentTypeId?: string;
  maxParticipants?: number;
  groupDescription?: string;
  appointmentStatus?: AppointmentStatusCode;
  bookedBy?: string;
  bookedByType?: BookedByTypeEnum;
  status?: CalendarEventStatus;
}

export type CalendarEventReminderMessageDto = {
  id: string;
  orgUnitId: string;
  calendarEventReminderId?: string;
  content?: string;
  deliveryStatusCode?: string;
  deliveryStatusUpdatedDateTime?: string;
  replyStatusCode?: string;
  deliveryChannelCode?: string;
  outboundCommContentId?: string;
  deliveryAddress?: string;
  contactHasOptedOut?: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
};

export type CalendarEventReminderDto = {
  id: string;
  outboundCommId?: string;
  deliveryStatusCode: string;
  replyStatusCode: string;
  calendarEventId: string;
  contactId: string;
  orgUnitId: string;
  eTag: string;
  changeLog?: ChangeLogDto;
};

export interface CalendarEventReminderSearchDto {
  patientId: string;
  providerId: string;
  calendarEvent: CalendarEventDto;
  reminder: CalendarEventReminderDto;
  message: CalendarEventReminderMessageDto;
}

export enum DateType {
  CalendarEvent = "CalendarEvent",
  Reply = "Reply",
  Sent = "Sent"
}

export type CalendarEventReminderSearchArgs = {
  dateType: DateType;
  orgUnitId?: string | undefined;
  phoneNumber?: string | undefined;
  startDateTime?: Date | undefined;
  endDateTime?: Date | undefined;
};

export interface CalendarEventReminderSearchArgsDto
  extends Omit<
    CalendarEventReminderSearchArgs,
    "startDateTime" | "endDateTime"
  > {
  startDateTime?: string | undefined;
  endDateTime?: string | undefined;
}

import { parse, stringify } from "query-string";
import { InfiniteData } from "react-query";
import { PagingOptions } from "./paging-options.type";
import { PagingResponse } from "./paging-response.type";
import { PagingTableStorageResponse } from "./paging-table-storage-response.type";

export function withPaging(queryString: {
  [key: string]: any;
}): { [key: string]: any };

export function withPaging<T extends PagingOptions>(
  queryString?: T
): string | { [key: string]: any } {
  let qs = {};

  if (typeof queryString === "string") {
    qs = parse(queryString);
  } else if (typeof queryString === "object") {
    // Adding paging to querystring with $ symbol
    const { page, limit, ...output } = {
      $page: queryString.page,
      $limit: queryString.limit,
      $count: true,
      ...queryString
    };
    qs = output;
  }

  return typeof queryString === "object" ? qs : stringify(qs);
}

export const getPagesResult = <T extends unknown>(
  respond:
    | InfiniteData<PagingResponse<T> | PagingTableStorageResponse<T, any>>
    | undefined
): T[] => {
  if (!respond) return [];
  return respond?.pages.reduce((acc: T[], pagingRespond) => {
    if (pagingRespond?.results) {
      return [...acc, ...pagingRespond.results];
    }
    return acc;
  }, [] as T[]);
};

import {
  HumanizeDuration,
  HumanizeDurationLanguage
} from "humanize-duration-ts";

const langService = new HumanizeDurationLanguage();
const humanizer = new HumanizeDuration(langService);

// eslint-disable-next-line import/no-default-export
export default humanizer;

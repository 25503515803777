import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";

import { ChangeLogDto } from "../../../types/common-dtos";
import { CommPreference } from "./ttm-comms-ops-gateway.dtos";
import { ITtmCommsOpsGateway } from "./ttm-comms-ops-gateway.interface";

interface CommPreferenceDto extends Omit<CommPreference, "changeLog"> {
  changeLog: ChangeLogDto;
}

export class TtmCommsOpsGateway implements ITtmCommsOpsGateway {
  constructor(private api: AxiosInstance) {}

  async getCommsPreferenes(tenantId: string): Promise<CommPreference[]> {
    const url = `tenants/${tenantId}/commsPreferences/`;
    const { data } = await this.api.get<CommPreferenceDto[]>(url);

    return data.map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }));
  }
}

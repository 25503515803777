import { useRootStore } from "@stores/StoresProvider";
import { useMutation } from "react-query";
import {
  NotificationDto,
  AddNotificationDto
} from "./plt-notifications-gateway.dtos";
import { HttpError } from "@bps/http-client";
import { useGateways } from "@libs/api/gateways-context";

export const useAddNotification = () => {
  const { pltNotificationsOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<NotificationDto, Error, AddNotificationDto>(
    pltNotificationsOpsGateway.addNotification,
    {
      onSuccess: () => {
        feedback.success("Notification succesfully sent");
      },
      onError: () => {
        feedback.error("Error sending notification");
      }
    }
  );
};

export const useBatchAddNotification = () => {
  const { pltNotificationsOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<void, HttpError, AddNotificationDto[]>(
    pltNotificationsOpsGateway.batchAddNotification,
    {
      onSuccess: () => {
        feedback.success("Notifications successfully sent");
      },
      onError: () => {
        feedback.error("Error sending notifications");
      }
    }
  );
};

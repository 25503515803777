import { AuthenticationOptions } from "@bps/http-client";
import { deepMerge, getEnv } from "../common/common.utils";

export type IEnv = "dev" | "uat" | "prod";

export interface IConfig {
  development: boolean;
  env: IEnv;
  version?: string;
  commitHash?: string;
  appInsights: { instrumentationKey: string };
  msal: AuthenticationOptions;
  notificationDuration: number;
  queryCacheDuration: number;
  platformBaseURL: string;
  platformIntegratorsBaseUrl: string;
  prosBaseURL: string;
  bhbBaseURL: string;
  tgOpsBaseUrl: string;
  siaOpsBaseUrl: string;
  fieldOpsBaseUrl: string;
  envBaseUrl: string;
  bestHealthOpsBaseUrl: string;
  bestHealthAccountOpsBaseUrl: string;
  bpIdOpsBaseUrl: string;
  camBaseURL: string;
  userExperienceBaseUrl: string;
  ttmBookingOpsBaseUrl: string;
  pltCommsOpsBaseUrl: string;
  ttmCommsOpsBaseUrl: string;
  bpMobileOpsBaseUrl: string;
  pltNotificationsOpsBaseUrl: string;
  ttmReportsOpsBaseUrl: string;
  ttmFormsOpsBaseUrl: string;
  platformRedirectApiUrl: string;
  seqUrl: string;
  azureMonitorUrl: string;
  azureResourcesSqlUrl: string;
  opsMonBaseURL: string;
  stripePublicAuKey: string;
  stripePublicNzKey: string;
}

// Runtime configuration loaded from the /public/config.js file
const runtimeConfig: Partial<IConfig> = window.appConfig || {};

// Environment configuration extracted from .env files.
const envConfig: IConfig = {
  appInsights: {
    instrumentationKey: import.meta.env.VITE_APP_INSIGHTS_INSTRUMENTATION_KEY
  },

  msal: {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    authority: import.meta.env.VITE_AUTH_AUTHORITY,
    scopes: import.meta.env.VITE_AUTH_SCOPE
      ? import.meta.env.VITE_AUTH_SCOPE?.split(",")
      : undefined,
    cacheLocation: "localStorage",
    postAuthenticateRedirectUrl: "/"
  },

  platformBaseURL: import.meta.env.VITE_PLATFORM_API_URL || undefined,
  platformIntegratorsBaseUrl:
    import.meta.env.VITE_PLATFORM_INTEGRATORS_API_URL || undefined,
  prosBaseURL: import.meta.env.VITE_PROS_API_URL || undefined,
  bhbBaseURL: import.meta.env.VITE_BHB_BASE_URL || undefined,
  tgOpsBaseUrl: import.meta.env.VITE_TG_OPS_API_URL || undefined,
  siaOpsBaseUrl: import.meta.env.VITE_SIA_OPS_API_URL || undefined,
  envBaseUrl: import.meta.env.VITE_ENV_API_URL || undefined,
  bestHealthOpsBaseUrl: import.meta.env.VITE_BH_OPS_API_URL || undefined,
  bestHealthAccountOpsBaseUrl:
    import.meta.env.VITE_BHA_OPS_API_URL || undefined,
  bpIdOpsBaseUrl: import.meta.env.VITE_BPID_OPS_API_URL || undefined,
  camBaseURL: import.meta.env.VITE_CAM_API_URL || undefined,
  userExperienceBaseUrl:
    import.meta.env.VITE_USEREXPERIENCE_API_URL || undefined,
  seqUrl: import.meta.env.VITE_SEQ_URL || undefined,
  azureMonitorUrl: import.meta.env.VITE_AZURE_MONITOR_URL || undefined,
  azureResourcesSqlUrl:
    import.meta.env.VITE_AZURE_RESOURCES_SQL_URL || undefined,
  opsMonBaseURL: import.meta.env.VITE_OPERATIONS_SYSTEM_API_URL || undefined,
  ttmBookingOpsBaseUrl:
    import.meta.env.VITE_TTM_BOOKING_OPS_API_URL || undefined,
  pltCommsOpsBaseUrl: import.meta.env.VITE_PLT_COMMS_OPS_API_URL || undefined,
  ttmCommsOpsBaseUrl: import.meta.env.VITE_TTM_COMMS_OPS_API_URL || undefined,
  bpMobileOpsBaseUrl: import.meta.env.VITE_BPMOBILE_OPS_API_URL || undefined,
  pltNotificationsOpsBaseUrl:
    import.meta.env.VITE_PLT_NOTIFICATIONS_OPS_API_URL || undefined,
  ttmReportsOpsBaseUrl:
    import.meta.env.VITE_TTM_REPORTS_OPS_API_URL || undefined,
  ttmFormsOpsBaseUrl: import.meta.env.VITE_TTM_FORMS_OPS_API_URL || undefined,
  fieldOpsBaseUrl: import.meta.env.VITE_FIELD_OPS_API_URL || undefined,
  platformRedirectApiUrl:
    import.meta.env.VITE_PLT_REDIRECT_OPS_API_URL || undefined,

  stripePublicAuKey: import.meta.env.VITE_STRIPE_PUBLIC_AU_KEY || undefined,
  stripePublicNzKey: import.meta.env.VITE_STRIPE_PUBLIC_NZ_KEY || undefined,

  development: /^https?:\/\/localhost/.test(window.location.toString()),
  env: getEnv(),
  notificationDuration: 4000,
  queryCacheDuration: 60000
};

const config = deepMerge<Partial<IConfig>>(runtimeConfig, envConfig) as IConfig;

export { config };

export const IsDevelopmentEnvironment =
  config.env.toLocaleLowerCase() === "dev";

import { guid } from "@libs/common/guid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { EntitySyncState } from "@libs/api/gateways/tg/model/EntitySyncState";
import { PremSettingsArgs } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { HttpError } from "@bps/http-client";
import { SiteSyncState } from "@libs/api/gateways/sia/models/SiteSyncState";
import {
  PremSettings,
  PremSettingsUpdate
} from "@libs/api/gateways/tg/tg-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const TgCacheKeys = {
  SiteSyncState: "pros-site-sync-state",
  EntitySyncState: "pros-entity-sync-state",
  PremSetting: "pros-prem-settings"
};

export const useProsEntitySyncStateQuery = (siteId: guid) => {
  const { tgOpsGateway } = useGateways();

  return useQuery<EntitySyncState>(
    [TgCacheKeys.EntitySyncState, siteId],
    async () => {
      const dto = await tgOpsGateway.getEntitySyncState(siteId);
      return new EntitySyncState(dto);
    }
  );
};

export const usePremSettingsQuery = (siteId: guid) => {
  const { tgOpsGateway } = useGateways();

  return useQuery<PremSettings[]>(
    [TgCacheKeys.PremSetting, siteId],
    async () => {
      return await tgOpsGateway.getPremSettings(siteId);
    }
  );
};

export const usePremSettingsMutation = () => {
  const { tgOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<PremSettingsUpdate[], HttpError, PremSettingsArgs>(
    ({ siteId, updates }) => tgOpsGateway.updatePremSettings(siteId, updates),
    {
      onSuccess: async (
        response: PremSettings[],
        { siteId }: PremSettingsArgs
      ) => {
        await queryClient.invalidateQueries([TgCacheKeys.PremSetting, siteId]);
      }
    }
  );
};

export const useRecalculateAvailabilityMutation = () => {
  const { tgOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    tgOpsGateway.recalculateAvailability(siteId)
  );
};

export const useSnapshotTablesMutation = () => {
  const { tgOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    tgOpsGateway.snapshotTables(siteId)
  );
};

export const useSiteSyncStateQuery = (siteId: guid) => {
  const { tgOpsGateway } = useGateways();

  return useQuery<SiteSyncState>(
    [TgCacheKeys.SiteSyncState, siteId],
    async () => {
      const dto = await tgOpsGateway.getReplicationSyncState(siteId);
      return new SiteSyncState(dto);
    }
  );
};

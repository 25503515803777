import { AxiosInstance } from "@bps/http-client";

import { TtmFormsRefreshOptions } from "./ttm-forms-ops-gateway.dtos";
import { ITTmFormsOpsGateway } from "@libs/api/gateways/ttm/forms/ttm-forms-ops-gateway.interface";

export class TtmFormsOpsGateway implements ITTmFormsOpsGateway {
  constructor(private api: AxiosInstance) {}

  async requestRefresh(
    tenantId: string,
    options?: TtmFormsRefreshOptions
  ): Promise<void> {
    const url = `tenants/${tenantId}/refreshFormDesign`;
    await this.api.post(url, options);
  }
}

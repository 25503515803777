import { AxiosInstance } from "@bps/http-client";
import { withPaging } from "@libs/paging/paging.utils";
import qs, { stringify } from "query-string";

import { DateTime } from "@bps/utils";

import { OutboundEmailAudit } from "./models/OutboundEmailAudit";
import { OutboundSmsAudit } from "./models/OutboundSmsAudit";
import {
  AddMessageCreditsEventDto,
  CreateOutboundEmailRequestArgs,
  CreateOutboundSmsRequestArgs,
  MessageCreditsBalance,
  MessageCreditsBalanceDto,
  MessageCreditsEvent,
  MessageCreditsEventDto,
  OutboundEmailAuditArgs,
  OutboundEmailAuditDto,
  OutboundEmailRequest,
  OutboundSmsAuditArgs,
  OutboundSmsAuditDto,
  OutboundSmsRequest
} from "./plt-comms-gateway.dtos";
import { PagingResponse } from "@libs/paging/paging-response.type";
import {
  PAGING_PAGE_NUMBER,
  PAGING_TOTAL_COUNT,
  PAGING_TOTAL_PAGES
} from "@libs/api/types/headers.constants";
import { IPltCommsOpsGateway } from "./plt-comms-ops-gateway.interface";

export class PltCommsOpsGateway implements IPltCommsOpsGateway {
  constructor(private api: AxiosInstance) {}

  // Message Credits Events/Balances
  getMessageCreditsBalances = async (
    tenantId: string
  ): Promise<MessageCreditsBalance[]> => {
    const url = qs.stringifyUrl({
      url: `tenants/${tenantId}/messageCreditsBalance`,
      query: {}
    });

    const { data } = await this.api.get<MessageCreditsBalanceDto>(url);

    //Treat the single balance as a list of 0..1
    const asList = data ? [data] : [];

    return asList.map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }));
  };

  getMessageCreditsEvents = async (
    tenantId: string
    // filter?: { doNotRenew?: boolean; isInactive: boolean }
  ): Promise<MessageCreditsEvent[]> => {
    const url = qs.stringifyUrl({
      url: `tenants/${tenantId}/messageCreditsEvents`,
      query: {}
    });

    const { data } = await this.api.get<MessageCreditsEventDto[]>(url);

    return data.map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }));
  };

  addMessageCreditsEvents = async (
    request: AddMessageCreditsEventDto
  ): Promise<MessageCreditsEvent> => {
    const { data } = await this.api.post<MessageCreditsEventDto>(
      "messageCreditsEvents",
      request
    );
    return [data].map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }))[0];
  };

  getOutboundSmsAudits = async (
    args: OutboundSmsAuditArgs,
    pageParam = 1
  ): Promise<PagingResponse<OutboundSmsAudit>> => {
    const fromDateFilter = args?.fromDate
      ? { fromDate: DateTime.fromJSDate(args?.fromDate).toISO() }
      : undefined;

    const toDateFilter = args?.toDate
      ? { toDate: DateTime.fromJSDate(args?.toDate).toISO() }
      : undefined;

    const url = qs.stringifyUrl({
      url: "/outboundSmsAudit",
      query: withPaging({
        phone: args.phone,
        tenantId: args.tenantId,
        ...fromDateFilter,
        ...toDateFilter,
        page: pageParam,
        limit: 150
      })
    });

    const response = await this.api.get<OutboundSmsAuditDto[]>(url);

    const isMaxPages =
      +response.headers[PAGING_PAGE_NUMBER] >
      +response.headers[PAGING_TOTAL_PAGES];

    const total = response.headers[PAGING_TOTAL_COUNT];

    return {
      results: response.data.map(dto => new OutboundSmsAudit(dto)),
      next: isMaxPages ? undefined : 2, // Not able to increment to next page
      total: total ? +total : undefined
    };
  };

  getOutboundEmailAudits = async (
    args: OutboundEmailAuditArgs
  ): Promise<PagingResponse<OutboundEmailAudit>> => {
    const queryString = withPaging({
      ...{
        searchTerm: args?.searchTerm!,
        endDate: DateTime.fromJSDate(args?.endDate!).toISO(),
        startDate: DateTime.fromJSDate(args?.startDate!).toISO()
      },
      page: 1,
      limit: 150
    });

    const response = await this.api.get<OutboundEmailAuditDto[]>(
      `/outboundEmail?${stringify(queryString)}`
    );

    const isMaxPages =
      +response.headers[PAGING_PAGE_NUMBER] >
      +response.headers[PAGING_TOTAL_PAGES];

    const total = response.headers[PAGING_TOTAL_COUNT];

    const data: PagingResponse<OutboundEmailAudit> = {
      results: response.data.map(dto => new OutboundEmailAudit(dto)),
      next: isMaxPages ? undefined : 2, // Not able to increment to next page
      total: total ? +total : undefined
    };

    return data;
  };

  createOutboundSmsRequest = async (
    args: CreateOutboundSmsRequestArgs
  ): Promise<OutboundSmsRequest> => {
    const { data } = await this.api.post<OutboundSmsRequest>(
      "outboundSmsRequest",
      args
    );
    return data;
  };

  createOutboundEmailRequest = async (
    args: CreateOutboundEmailRequestArgs
  ): Promise<OutboundEmailRequest> => {
    const { data } = await this.api.post<OutboundEmailRequest>(
      "outboundEmailRequest",
      args
    );
    return data;
  };
}

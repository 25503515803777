import { AxiosInstance } from "@bps/http-client";
import {
  AddNotificationDto,
  NotificationDto
} from "./plt-notifications-gateway.dtos";
import { IPlatformNotificationsGateway } from "./plt-notifications-gateway.interface";

export class PlatformNotificationsGateway
  implements IPlatformNotificationsGateway {
  constructor(private api: AxiosInstance) {}

  addNotification = async (
    notification: AddNotificationDto
  ): Promise<NotificationDto> => {
    const { data } = await this.api.post<NotificationDto>(
      "notifications",
      notification
    );
    return data;
  };

  batchAddNotification = async (
    notifications: AddNotificationDto[]
  ): Promise<void> => {
    await this.api.post("notifications/$batch", notifications);
  };
}

import { useQuery } from "react-query";

import { CommPreference } from "./ttm-comms-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const TtmCommsCacheKeys = {
  CommPreferences: "ttm-comms-commspreferences"
};

export const useCommPreferences = (tenantId: string) => {
  const { ttmCommsOpsGateway } = useGateways();

  return useQuery<CommPreference[]>(
    [TtmCommsCacheKeys.CommPreferences, tenantId],
    async () => {
      return await ttmCommsOpsGateway.getCommsPreferenes(tenantId);
    }
  );
};

import { AxiosInstance } from "@bps/http-client";
import qs from "query-string";

import {
  CustomerAccount,
  CustomerAccountDto,
  CustomerAccountSearchArgs,
  CustomerAccountUser,
  CustomerAccountUserCreateArgs,
  CustomerAccountUserDto,
  ServiceAgreementDto,
  ServiceAgreementsArgs
} from "./cam-gateway.dtos";
import { toCustomerAccount } from "./cam-gateway.utils";
import { PltUser, Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { ICamGateway } from "@libs/api/gateways/cam/cam-gateway.interface";

export class CamGateway implements ICamGateway {
  constructor(private api: AxiosInstance) {}

  createCustomerAccountUser = async (
    args: CustomerAccountUserCreateArgs
  ): Promise<CustomerAccountUser> => {
    const { data } = await this.api.post<CustomerAccountUserDto>("users", args);

    return {
      tenantUserId: data?.userId,
      tenantId: data?.tenantId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      bpIdUserId: data?.bpIdUserId
    };
  };

  async getCustomerAccounts(
    args: CustomerAccountSearchArgs
  ): Promise<CustomerAccount[]> {
    const { data } = await this.api.post<CustomerAccountDto[]>(
      "account/search",
      args
    );

    return data.map(toCustomerAccount);
  }

  async getCustomerAccount(crmId: string): Promise<CustomerAccount> {
    const url = qs.stringifyUrl({
      url: `account/${crmId}`
    });

    const { data } = await this.api.get<CustomerAccountDto>(url);

    const { ...dto } = data;
    return toCustomerAccount(dto);
  }

  async getCustomerAccountUsers(crmId: string): Promise<PltUser[]> {
    const url = qs.stringifyUrl({
      url: "account/users",
      query: { crmId }
    });

    const { data } = await this.api.get<PltUser[]>(url);
    return data;
  }

  async getAllCustomerAccountUsers(
    crmId: string
  ): Promise<CustomerAccountUser[]> {
    const url = qs.stringifyUrl({
      url: "users/all",
      query: { crmId }
    });

    const { data } = await this.api.get<CustomerAccountUser[]>(url);
    return data;
  }

  async createCustomerAccount(
    crmId: string,
    businessName?: string
  ): Promise<CustomerAccount> {
    const url = qs.stringifyUrl({
      url: "account",
      query: {
        crmId,
        name: businessName
      }
    });

    const { data: dto } = await this.api.post(url);

    return {
      id: dto.id!,
      crmId: dto.crmId,
      tenantName: dto.tenantName ?? "",
      businessName: dto.businessName ?? "",
      customerTenantId: dto.customerTenantId,
      customerTenantCrmId: dto.customerTenantCrmId,
      headOfficeId: dto.headOfficeId,
      headOfficeBusinessName: dto.headOfficeBusinessName,
      headOfficeCrmId: dto.headOfficeCrmId,
      billingClientId: dto.billingClientId,
      billingClientBusinessName: dto.billingClientBusinessName,
      billingClientCrmId: dto.billingClientCrmId,
      email1: dto.email_1,
      email2: dto.email_2,
      contactName: dto.contact_name,
      addressLine1: dto.address_line1,
      addressLine2: dto.address_line2,
      addressLine3: dto.address_line3,
      city: dto.city,
      state: dto.state,
      postcode: dto.postcode,
      country: dto.country ?? "",
      existsInCrm: dto.existsInCrm ?? false,
      tenants: [],
      caTenants: []
    };
  }

  async getServiceAgreements(
    args: ServiceAgreementsArgs
  ): Promise<ServiceAgreementDto[]> {
    const url = qs.stringifyUrl({
      url: "serviceAgreement",
      query: { ...args }
    });

    return (await this.api.get<ServiceAgreementDto[]>(url)).data;
  }

  async resetCustomerAccount(tenantId: string): Promise<Tenant> {
    const url = qs.stringifyUrl({
      url: `customers/${tenantId}/reset`
    });

    const { data } = await this.api.put<Tenant>(url);

    return data;
  }
}

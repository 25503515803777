import { useQuery } from "react-query";

import { useGateways } from "@libs/api/gateways-context";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";
import { guid } from "@libs/common/guid";

const CacheKeys = {
  TenantSeqLogs: "tenant-seq-logs",
  TenantAzureMonitorLogs: "tenant-azure-logs",
  FieldDeviceSeqLogs: "fieldDevice-seq-logs",
  FieldDeviceAzureMonitorLogs: "fieldDevice-azure-logs"
};

export const useTenantSeqLogsQuery = (tenantId: guid, siteId?: string) => {
  const { userExperienceGateway } = useGateways();

  return useQuery<LogEntry[]>([CacheKeys.TenantSeqLogs, tenantId], async () => {
    return await userExperienceGateway.getTenantSeqLogs(tenantId, siteId);
  });
};

export const useTenantAzureMonitorLogsQuery = (
  tenantId: guid,
  siteId?: string
) => {
  const { userExperienceGateway } = useGateways();

  return useQuery<LogEntry[]>(
    [CacheKeys.TenantAzureMonitorLogs, tenantId],
    async () => {
      return await userExperienceGateway.getTenantAzureMonitorLogs(
        tenantId,
        siteId
      );
    }
  );
};

export const useFieldDeviceSeqLogsQuery = (
  cloudConnectorId: string,
  fieldDeviceId: string
) => {
  const { userExperienceGateway } = useGateways();

  return useQuery<LogEntry[]>(
    [CacheKeys.FieldDeviceSeqLogs, fieldDeviceId],
    async () => {
      return await userExperienceGateway.getDeviceSeqLogs(
        cloudConnectorId,
        fieldDeviceId
      );
    }
  );
};

export const useFieldDeviceAzureMonitorLogsQuery = (
  cloudConnectorId: string,
  fieldDeviceId: string
) => {
  const { userExperienceGateway } = useGateways();

  return useQuery<LogEntry[]>(
    [CacheKeys.FieldDeviceAzureMonitorLogs, fieldDeviceId],
    async () => {
      return await userExperienceGateway.getDeviceAzureMonitorLogs(
        cloudConnectorId,
        fieldDeviceId
      );
    }
  );
};

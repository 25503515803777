import { CustomerAccount, CustomerAccountDto } from "./cam-gateway.dtos";

export const toCustomerAccount = (dto: CustomerAccountDto): CustomerAccount => {
  return {
    id: dto.id!,
    crmId: dto.crmId,
    tenantName: dto.tenantName,
    businessName: dto.businessName ?? "",
    customerTenantId: dto.customerTenantId,
    customerTenantCrmId: dto.customerTenantCrmId,
    headOfficeId: dto.headOfficeId,
    headOfficeBusinessName: dto.headOfficeBusinessName,
    headOfficeCrmId: dto.headOfficeCrmId,
    billingClientId: dto.billingClientId,
    billingClientBusinessName: dto.billingClientBusinessName,
    billingClientCrmId: dto.billingClientCrmId,
    email1: dto.email_1,
    email2: dto.email_2,
    contactName: dto.contact_name,
    addressLine1: dto.address_line1,
    addressLine2: dto.address_line2,
    addressLine3: dto.address_line3,
    city: dto.city,
    state: dto.state,
    postcode: dto.postcode,
    country: dto.country ?? "",
    existsInCrm: dto.existsInCrm ?? false,
    tenants: [],
    caTenants: []
  };
};

import { stringify } from "query-string";
import { useQuery } from "react-query";

import {
  AppointmentReminderJob,
  AppointmentReminderJobRun,
  BookingTenantSettings,
  CalendarEventReminderSearchArgs,
  CalendarEventReminderSearchDto
} from "./ttm-booking-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const TtmBookingCacheKeys = {
  AppointmentReminderSearch: "ttm-booking-appointmentremindersearch",
  AppointmentReminderJobs: "ttm-booking-appointmentreminderjobs",
  AppointmentReminderJobRuns: "ttm-booking-appointmentreminderjobruns",
  BookingTenantSettings: "ttm-booking-tenantsettings"
};

export const useAppointmentReminderJobs = (tenantId: string) => {
  const { ttmBookingOpsGateway } = useGateways();

  return useQuery<AppointmentReminderJob[]>(
    [TtmBookingCacheKeys.AppointmentReminderJobs, tenantId],
    async () => {
      return await ttmBookingOpsGateway.getAppointmentReminderJobs(tenantId);
    }
  );
};

export const useAppointmentReminderJobRuns = (
  tenantId: string,
  jobId: string
) => {
  const { ttmBookingOpsGateway } = useGateways();

  return useQuery<AppointmentReminderJobRun[]>(
    [TtmBookingCacheKeys.AppointmentReminderJobRuns, jobId],
    async () => {
      return await ttmBookingOpsGateway.getAppointmentReminderJobRuns(
        tenantId,
        jobId
      );
    }
  );
};

export const useBookingTenantSettings = (tenantId: string) => {
  const { ttmBookingOpsGateway } = useGateways();

  return useQuery<BookingTenantSettings>(
    [TtmBookingCacheKeys.BookingTenantSettings, tenantId],
    async () => {
      return await ttmBookingOpsGateway.getTenantSettings(tenantId);
    }
  );
};

export const useAppointmentReminderSearch = (
  tenantId: string,
  searchArgs: CalendarEventReminderSearchArgs
) => {
  const { ttmBookingOpsGateway } = useGateways();

  return useQuery<CalendarEventReminderSearchDto[]>(
    [
      TtmBookingCacheKeys.AppointmentReminderSearch,
      tenantId,
      stringify(searchArgs)
    ],
    async () => {
      return await ttmBookingOpsGateway.getCalendarEventRemindersBySearchArgs(
        tenantId,
        searchArgs
      );
    },
    {
      staleTime: 10000,
      enabled: !!searchArgs.startDateTime && !!searchArgs.endDateTime
    }
  );
};

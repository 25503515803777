import qs from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IUserExperienceGateway } from "@libs/api/gateways/user-experience/user-experience-gateway.interface";
import { LogEntry } from "@libs/api/gateways/user-experience/user-experience.dtos";

export class UserExperienceGateway implements IUserExperienceGateway {
  constructor(private api: AxiosInstance) {}

  async getTenantSeqLogs(
    tenantId: string,
    siteId?: string
  ): Promise<LogEntry[]> {
    const url = qs.stringifyUrl({
      url: "/logs/tenant/seq",
      query: { tenantId, siteId: Number(siteId?.replace("BPS-", "")) } // Strip BPS off siteId
    });

    const { data } = await this.api.get<LogEntry[]>(url);
    return data;
  }

  async getTenantAzureMonitorLogs(
    tenantId: string,
    siteId?: string
  ): Promise<LogEntry[]> {
    const url = qs.stringifyUrl({
      url: "/logs/tenant/azure",
      query: { tenantId, siteId: Number(siteId?.replace("BPS-", "")) } // Strip BPS off siteId
    });

    const { data } = await this.api.get<LogEntry[]>(url);
    return data;
  }

  async getDeviceSeqLogs(
    cloudConnectorId: string,
    fieldDeviceId?: string
  ): Promise<LogEntry[]> {
    const url = qs.stringifyUrl({
      url: "/logs/fieldDevice/seq",
      query: { cloudConnectorId, fieldDeviceId }
    });

    const { data } = await this.api.get<LogEntry[]>(url);
    return data;
  }

  async getDeviceAzureMonitorLogs(
    cloudConnectorId: string,
    fieldDeviceId?: string
  ): Promise<LogEntry[]> {
    const url = qs.stringifyUrl({
      url: "/logs/fieldDevice/azure",
      query: { cloudConnectorId, fieldDeviceId }
    });

    const { data } = await this.api.get<LogEntry[]>(url);
    return data;
  }
}

import { AxiosInstance } from "@bps/http-client";
import { stringify } from "query-string";

import { GetRolesArgs, IEnvGateway } from "./env-gateway.inferface";
import { AadUser, EnvRoleDto, EnvUser, NewEnvUser } from "./env-gateway.dtos";

export class EnvGateway implements IEnvGateway {
  constructor(private api: AxiosInstance) {}

  getCurrentUser = async (): Promise<EnvUser> => {
    const { data } = await this.api.get("users/me");
    return data;
  };

  getUsers = async (): Promise<EnvUser[]> => {
    const { data } = await this.api.get("users");
    return data;
  };

  searchUsers = async (name: string): Promise<AadUser[]> => {
    const { data } = await this.api.get(`users/search?name=${name}`);
    return data;
  };

  updateUser = async (newUser: NewEnvUser): Promise<EnvUser> => {
    const { data } = await this.api.put(`users/${newUser.id}`, newUser);
    return data;
  };

  createUser = async (user: EnvUser): Promise<EnvUser> => {
    const { data } = await this.api.post("users", user);
    return data;
  };

  getRoles = async (args?: GetRolesArgs): Promise<EnvRoleDto[]> => {
    const query = args
      ? `?${stringify({ isInactive: args.isInactive, roles: args.roles })}`
      : "";
    return (await this.api.get(`roles${query}`)).data;
  };

  getAllRoles = async (): Promise<EnvRoleDto[]> => {
    return (await this.api.get("roles/all")).data;
  };
}

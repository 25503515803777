import { compareDatesPredicate, DateTime } from "@bps/utils";
import { IEnv } from "@libs/config/config";

export const convertBytes = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "n/a";
  }

  const i: number = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

export const compareDatesOrUndefinedPredicate = (
  a: string | Date | DateTime | undefined,
  b: string | Date | DateTime | undefined,
  isSortedDescending?: boolean
) => {
  if (!a) {
    return 1;
  }

  if (!b) {
    return -1;
  }

  const dateA = a instanceof DateTime ? a : DateTime.fromJSDate(new Date(a));
  const dateB = b instanceof DateTime ? b : DateTime.fromJSDate(new Date(b));

  return compareDatesPredicate(dateA, dateB, isSortedDescending);
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const deepMerge = <T extends Object>(...objects: T[]) => {
  return objects.reduce((src, dest) => {
    if (!dest) return src;

    Object.keys(src).forEach(key => {
      const destValue = dest[key];
      const srcValue = src[key];

      if (typeof srcValue === "object") {
        dest[key] = deepMerge(srcValue, destValue);
      } else if (destValue !== undefined) {
        dest[key] = destValue;
      } else {
        dest[key] = srcValue;
      }
    });

    return dest;
  });
};

export const getEnv = (): IEnv => {
  if (/uat.\.bpcloud.dev/.test(window.location.href)) {
    return "uat";
  } else if (/bpcloud.io/.test(window.location.href)) {
    return "prod";
  } else {
    return "dev";
  }
};

import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { config } from "@libs/config/config";
import { AxiosHelper, EndpointConfig } from "@bps/http-client";
import { OpsApiSchemaInterface } from "@libs/api/types/ops-api-schema.interface";
import { CorrelationBaggageMiddleware } from "@libs/api/middleware/corraletion-baggage.middleware";
import { NO_AUTH } from "@libs/api/types/headers.constants";
import { PlatformGateway } from "@libs/api/gateways/plt/PlatformGateway";
import { TgOpsGateway } from "@libs/api/gateways/tg/TgOpsGateway";
import { SiaOpsGateway } from "@libs/api/gateways/sia/SiaOpsGateway";
import { FieldOpsGateway } from "@libs/api/gateways/field/FieldOpsGateway";
import { EnvGateway } from "@libs/api/gateways/env/EnvGateway";
import { BestHealthOpsGateway } from "@libs/api/gateways/best-health/practice/BestHealthOpsGateway";
import { BestHealthAccountOpsGateway } from "@libs/api/gateways/best-health/account/BestHealthAccountOpsGateway";
import { TtmReportingOpsGateway } from "@libs/api/gateways/ttm/reporting/TtmReportingOpsGateway";
import { TtmFormsOpsGateway } from "@libs/api/gateways/ttm/forms/TtmFormsOpsGateway";
import { OpsMonGateway } from "@libs/api/gateways/mon/OpsMonGateway";
import { TtmBookingOpsGateway } from "@libs/api/gateways/ttm/booking/TtmBookingOpsGateway";
import { PltCommsOpsGateway } from "@libs/api/gateways/plt-comms/PltCommsOpsGateway";
import { TtmCommsOpsGateway } from "@libs/api/gateways/ttm/comms/TtmCommsOpsGateway";
import { BpMobileOpsGateway } from "@libs/api/gateways/bp-mobile/BpMobileOpsGateway";
import { PlatformNotificationsGateway } from "@libs/api/gateways/plt-notifications/PlatformNotificationsGateway";
import { PlatformRedirectGateway } from "@libs/api/gateways/plt-redirect/PlatformRedirectGateway";
import { BpIdOpsGateway } from "@libs/api/gateways/bp-id/BpIdOpsGateway";
import { CamGateway } from "@libs/api/gateways/cam/CamGateway";
import { UserExperienceGateway } from "@libs/api/gateways/user-experience/UserExperienceGateway";
import { PlatformIntegratorsGateway } from "@libs/api/gateways/plt-integrators/PlatformIntegratorsGateway";
import { GatewaysContext } from "@libs/api/gateways-context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: config.queryCacheDuration
    }
  }
});

export const ReactQueryProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const TRACE_ID = "OpsConsole";

  const opsEndpoints: EndpointConfig<OpsApiSchemaInterface> = {
    platform: { baseURL: config.platformBaseURL },
    platformIntegration: { baseURL: config.platformIntegratorsBaseUrl },
    tg: { baseURL: config.tgOpsBaseUrl },
    sia: { baseURL: config.siaOpsBaseUrl },
    field: { baseURL: config.fieldOpsBaseUrl },
    bestHealth: { baseURL: config.bestHealthOpsBaseUrl },
    bestHealthAccount: { baseURL: config.bestHealthAccountOpsBaseUrl }, //
    bpId: { baseURL: config.bpIdOpsBaseUrl },
    cam: { baseURL: config.camBaseURL },
    userExperience: { baseURL: config.userExperienceBaseUrl },
    ttmReporting: { baseURL: config.ttmReportsOpsBaseUrl },
    ttmForms: { baseURL: config.ttmFormsOpsBaseUrl },
    mon: { baseURL: config.opsMonBaseURL },
    ttmBooking: { baseURL: config.ttmBookingOpsBaseUrl },
    pltComms: { baseURL: config.pltCommsOpsBaseUrl },
    ttmComms: { baseURL: config.ttmCommsOpsBaseUrl },
    bpMobile: { baseURL: config.bpMobileOpsBaseUrl },
    pltNotifications: { baseURL: config.pltNotificationsOpsBaseUrl },
    pltRedirect: { baseURL: config.platformRedirectApiUrl },
    env: { baseURL: config.envBaseUrl }
  };

  const axiosHelper = new AxiosHelper<OpsApiSchemaInterface>(
    opsEndpoints,
    { request: [new CorrelationBaggageMiddleware()] },
    {
      noAuthHeader: NO_AUTH,
      traceId: TRACE_ID
    }
  );

  const api = axiosHelper.initialize();

  const platformGateway = new PlatformGateway(api.platform);
  const tgOpsGateway = new TgOpsGateway(api.tg);
  const siaOpsGateway = new SiaOpsGateway(api.sia);
  const fieldOpsGateway = new FieldOpsGateway(api.field);
  const envGateway = new EnvGateway(api.env);
  const bestHealthOpsGateway = new BestHealthOpsGateway(api.bestHealth);
  const bestHealthAccountOpsGateway = new BestHealthAccountOpsGateway(
    api.bestHealthAccount
  );

  const ttmReportingOpsGateway = new TtmReportingOpsGateway(api.ttmReporting);
  const ttmFormsOpsGateway = new TtmFormsOpsGateway(api.ttmForms);
  const opsMonGateway = new OpsMonGateway(api.mon);
  const ttmBookingOpsGateway = new TtmBookingOpsGateway(api.ttmBooking);
  const pltCommsOpsGateway = new PltCommsOpsGateway(api.pltComms);
  const ttmCommsOpsGateway = new TtmCommsOpsGateway(api.ttmComms);
  const bpMobileOpsGateway = new BpMobileOpsGateway(api.bpMobile);
  const pltNotificationsOpsGateway = new PlatformNotificationsGateway(
    api.pltNotifications
  );

  const pltRedirectOpsGateway = new PlatformRedirectGateway(api.pltRedirect);
  const bpIdOpsGateway = new BpIdOpsGateway(api.bpId);
  const camGateway = new CamGateway(api.cam);
  const userExperienceGateway = new UserExperienceGateway(api.userExperience);
  const platformIntegratorsGateway = new PlatformIntegratorsGateway(
    api.platformIntegration
  );

  return (
    <GatewaysContext.Provider
      value={{
        platformGateway,
        tgOpsGateway,
        siaOpsGateway,
        fieldOpsGateway,
        envGateway,
        bestHealthOpsGateway,
        bestHealthAccountOpsGateway,
        bpIdOpsGateway,
        camGateway,
        opsMonGateway,
        ttmBookingOpsGateway,
        pltCommsOpsGateway,
        ttmCommsOpsGateway,
        userExperienceGateway,
        pltNotificationsOpsGateway,
        ttmReportingOpsGateway,
        ttmFormsOpsGateway,
        platformIntegratorsGateway,
        pltRedirectOpsGateway,
        bpMobileOpsGateway
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </GatewaysContext.Provider>
  );
};

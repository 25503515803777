import { AxiosInstance } from "@bps/http-client";
import { IBpMobileOpsGateway } from "./BpMobileOpsGateway.interface";
import { BpMobileMaintenanceSettings } from "./bp-mobile.dtos";

export class BpMobileOpsGateway implements IBpMobileOpsGateway {
  constructor(private api: AxiosInstance) {}

  public async getMobileAppSettings(): Promise<BpMobileMaintenanceSettings> {
    const { data } = await this.api.get<BpMobileMaintenanceSettings>(
      "settings"
    );
    return data;
  }

  public async putMobileAppSettings(
    updates: BpMobileMaintenanceSettings
  ): Promise<BpMobileMaintenanceSettings> {
    const { data } = await this.api.put<BpMobileMaintenanceSettings>(
      "settings",
      updates
    );
    return data;
  }
}

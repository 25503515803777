import { DateTime } from "@bps/utils";

export interface SiteVersions {
  FailureMessage: string;
  IsSiteAgentDesiredVersionDownloaded: boolean;
  ResponseDateTimeUTC?: DateTime;
  SiteAgentInstalledVersion: string;
  SiteManagerInstalledVersion: string;
  Success: boolean;
}

export enum SiteComponentType {
  Bootstrapper = "sitebootstrapinstaller",
  SiteAgent = "siteagent",
  SiteManager = "sitemanager"
}

export enum SiteCommand {
  Configuration = "CONFIGURATION",
  ChangeState = "CHANGESTATE",
  UploadLog = "UPLOADLOG"
}

export enum SiteComponentState {
  Download = "Download",
  Update = "ApplyUpdate",
  Install = "Install",
  Uninstall = "Uninstall",
  Start = "Start",
  Stop = "Stop",
  Restart = "Restart"
}

export enum ProsDatabaseCommands {
  InstallUpdate = "installupdate",
  Enable = "enable",
  Uninstall = "uninstall",
  EnableChangeTracking = "enablechangetracking",
  DisableChangeTracking = "disablechangetracking"
}

export interface SiteComponentVersionDto {
  component: SiteComponentType;
  versions: SiteComponentVersionResponse[];
}

export interface SiteComponentVersionResponse {
  version: string;
  createdDate: DateTime;
}

export interface SiteComponentVersionApproval {
  componentType: SiteComponentType;
  componentVersion: string;
  id: string;
  createdDate: DateTime;
  createdBy: string;
}

export interface VersionApprovalRequest {
  componentType: SiteComponentType;
  componentVersion: string;
}

export interface SiteCommandUpdateArgs {
  tenantId: string;
  commandType: string;
  componentType: string;
  subCommandType?: string;
  scheduled?: DateTime;
}

export interface GetTenantsParams {
  isInactive?: boolean;
  application?: string;
  tenantIds?: string[];
  featureCodes?: string[];
}

export interface SiteVersionsDto
  extends Omit<SiteVersions, "ResponseDateTimeUTC"> {
  ResponseDateTimeUTC: string;
}

export interface SiteAvailableReport {
  id: string;
  name: string;
}

// This is just a marker interface - we don't type the result as we just display it as JSON
export interface SiteServerReport {}

export enum ReportsType {
  server = "server",
  sql = "sql"
}

export interface InstallerDetail {
  name: string;
  version: string;
  url: string;
}

export interface SIATenantDetails {
  id: string;
  siteId: string;
  name?: string;
  tenantType?: string;
}

export interface ProsTenant {
  id: string;
  name?: string;
  application?: string;
}

export enum LogLevels {
  none = "None",
  trace = "Trace",
  debug = "Debug",
  information = "Information",
  warning = "Warning",
  error = "Error",
  critical = "Critical"
}

export interface SiteComponent {
  type: SiteComponentType;
  name: string;
}

export interface SequenceNumberSyncState {
  sequenceNumber: number | null;
  sentAt?: DateTime;
  receivedAt?: DateTime;
  processedAt?: DateTime;
  receiveDelayMillis?: number;
  processingDelayMillis?: number;
  status?: "Queued" | "Dequeued" | "Processed";
}

import { useMutation, useQuery, useQueryClient } from "react-query";

import { PoisonMessage } from "@libs/api/gateways/mon/ops-mon-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

const CacheKeys = {
  PoisonMessages: "poison-messages"
};

export const usePoisonMessagesQuery = () => {
  const { opsMonGateway } = useGateways();
  return useQuery<PoisonMessage[]>(CacheKeys.PoisonMessages, async () => {
    try {
      const data = await opsMonGateway.getPoisonMessages();

      if (data.length > 0) {
        return data;
      }
      return [];
    } catch (e) {
      if (e.code === 204) {
        return [];
      }

      throw e;
    }
  });
};

export const useSkipPoisonMessagesMutation = () => {
  const { opsMonGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<PoisonMessage, Error, PoisonMessage>(
    msg => opsMonGateway.skipPoisonMessage(msg),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(CacheKeys.PoisonMessages);
      },
      onError: () => {}
    }
  );
};

import { AxiosInstance } from "@bps/http-client";
import { EntitySyncStateDto } from "@libs/api/gateways/tg/model/EntitySyncState";
import { SiteSyncStateDto } from "@libs/api/gateways/sia/models/SiteSyncState";

import { DateTime } from "@bps/utils";
import { ITgOpsGateway } from "@libs/api/gateways/tg/tg-ops-gateway.interface";
import {
  PremSettings,
  PremSettingsDto,
  PremSettingsUpdate
} from "@libs/api/gateways/tg/tg-ops-gateway.dtos";

export class TgOpsGateway implements ITgOpsGateway {
  constructor(private api: AxiosInstance) {}

  async getReplicationSyncState(siteId: string): Promise<SiteSyncStateDto> {
    const config = {
      headers: {
        "X-Tenant": siteId
      }
    };

    const { data } = await this.api.get<SiteSyncStateDto>(
      "/siteState/sync",
      config
    );
    return data;
  }

  async recalculateAvailability(siteId: string) {
    const config = {
      headers: {
        "X-Tenant": siteId
      }
    };
    await this.api.post("/availability/recalculate", null, config);
    return "Ok";
  }

  async snapshotTables(siteId: string): Promise<string> {
    const config = {
      headers: {
        "X-Tenant": siteId
      }
    };

    const { data } = await this.api.post(
      `/siteState/snapshot/${siteId}`,
      null,
      config
    );
    return data;
  }

  async getEntitySyncState(siteId: string): Promise<EntitySyncStateDto> {
    const config = {
      headers: {
        "X-Tenant": siteId
      }
    };

    const { data } = await this.api.get<EntitySyncStateDto>(
      "/entitySyncState",
      config
    );
    return data;
  }

  async getPremSettings(siteId: string): Promise<PremSettings[]> {
    const config = {
      headers: {
        "X-Tenant": siteId
      },
      params: {
        tenantId: siteId
      }
    };

    const { data } = await this.api.get<PremSettingsDto[]>(
      "/premSettings",
      config
    );
    return data.map(({ ...dto }) => {
      const { created, updated, ...rest } = dto;
      return {
        created: DateTime.fromISO(created),
        updated: DateTime.fromISO(updated),
        ...rest
      };
    });
  }

  async updatePremSettings(
    siteId: string,
    updates: PremSettingsUpdate[]
  ): Promise<PremSettings[]> {
    const config = {
      headers: {
        "X-Tenant": siteId
      },
      params: {
        tenantId: siteId
      }
    };

    const { data } = await this.api.put<PremSettingsDto[]>(
      "/premSettings",
      updates,
      config
    );

    return data.map(({ ...dto }) => {
      const { created, updated, ...rest } = dto;
      return {
        created: DateTime.fromISO(created),
        updated: DateTime.fromISO(updated),
        ...rest
      };
    });
  }
}

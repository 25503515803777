import { AxiosInstance } from "@bps/http-client";

import { PoisonMessage } from "./ops-mon-gateway.dtos";
import { IOpsMonGateway } from "./ops-mon-gateway.interface";

export class OpsMonGateway implements IOpsMonGateway {
  constructor(private api: AxiosInstance) {}

  async getPoisonMessages(): Promise<PoisonMessage[]> {
    const { data } = await this.api.get("eventHubs/poisonMessages");
    return data;
  }

  async skipPoisonMessage(message: PoisonMessage) {
    await this.api.post("eventHubs/poisonMessages/skip", {
      source: message.source,
      partitionKey: message.partitionKey,
      rowKey: message.rowKey
    });
  }
}

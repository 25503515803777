import { DateTime } from "@bps/utils";

import { OutboundEmailAuditDto } from "../plt-comms-gateway.dtos";

export class OutboundEmailAudit {
  private dto: OutboundEmailAuditDto;
  constructor(dto: OutboundEmailAuditDto) {
    this.dto = dto;
  }

  get id() {
    return this.dto.id;
  }

  get sourceTenantId() {
    return this.dto.sourceTenantId;
  }

  get sourceId() {
    return this.dto.sourceId;
  }

  get subject() {
    return this.dto.subject;
  }

  get email() {
    return this.dto.to;
  }

  get etag() {
    return this.dto.etag;
  }

  get createdDate(): DateTime | undefined {
    return this.dto.changeLog.createdDate
      ? DateTime.fromISO(this.dto.changeLog.createdDate)
      : undefined;
  }
}

import { AxiosInstance } from "@bps/http-client";
import { guid } from "@libs/common/guid";
import qs from "query-string";

import { DateTime } from "@bps/utils";
import { IBestHealthOpsGateway } from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.interface";
import {
  AppointmentStats,
  AppointmentStatsDto,
  EntitySequence,
  LocationAppointmentStats,
  LocationAppointmentStatsDto,
  PracticeLocation,
  PracticeLocationDto,
  PracticeLocationUpdate,
  Provider,
  ProviderDto
} from "@libs/api/gateways/best-health/practice/best-health-ops-gateway.dtos";
import { EntitySequenceDto } from "@libs/api/gateways/tg/model/EntitySyncState";

export class BestHealthOpsGateway implements IBestHealthOpsGateway {
  constructor(private api: AxiosInstance) {}

  public async getPracticeLocations(
    tenantId: guid
  ): Promise<PracticeLocation[]> {
    const { data } = await this.api.get<PracticeLocationDto[]>(
      `/location/${tenantId}`
    );

    return data.map(({ onlineBookingsEnabled, providers, ...rest }) => ({
      ...rest,

      bookingSettings: {
        enabled: !!onlineBookingsEnabled
      },
      providers:
        providers?.map(provider => ({
          ...provider,
          firstAvailable: provider.firstAvailable
            ? DateTime.fromISO(provider.firstAvailable)
            : undefined,
          lastAvailable: provider.lastAvailable
            ? DateTime.fromISO(provider.lastAvailable)
            : undefined
        })) || []
    }));
  }

  public async putPracticeLocation(
    locationId: guid,
    updates: PracticeLocationUpdate
  ): Promise<PracticeLocation> {
    const { data } = await this.api.put<PracticeLocationDto>(
      `/location/${locationId}`,
      updates
    );
    return {
      ...data,
      bookingSettings: {
        enabled: !!data.onlineBookingsEnabled
      },
      providers:
        data.providers?.map(provider => ({
          ...provider,
          firstAvailable: provider.firstAvailable
            ? DateTime.fromISO(provider.firstAvailable)
            : undefined,
          lastAvailable: provider.lastAvailable
            ? DateTime.fromISO(provider.lastAvailable)
            : undefined
        })) || []
    };
  }

  public async getLocationAppointmentStats(
    tenantId: guid
  ): Promise<LocationAppointmentStats[]> {
    const url = qs.stringifyUrl({
      url: `/appointmentStats/${tenantId}/locations`,
      query: {
        browserTime: DateTime.now().toISO()
      }
    });

    const { data } = await this.api.get<LocationAppointmentStatsDto[]>(url);

    return data.map(({ lastCreated, ...rest }) => ({
      ...rest,
      lastCreated: lastCreated ? DateTime.fromISO(lastCreated) : undefined
    }));
  }

  public async getAppointmentStats(tenantId: guid): Promise<AppointmentStats> {
    const url = qs.stringifyUrl({
      url: `/appointmentStats/${tenantId}`,
      query: {
        browserTime: DateTime.now().toISO()
      }
    });

    const { data } = await this.api.get<AppointmentStatsDto>(url);

    const { lastCreated, ...stats } = data;
    return {
      ...stats,
      lastCreated: lastCreated ? DateTime.fromISO(lastCreated) : undefined
    };
  }

  public async getAllAppointmentStats(): Promise<AppointmentStats[]> {
    const url = qs.stringifyUrl({
      url: "/appointmentStats",
      query: {
        browserTime: DateTime.now().toISO()
      }
    });

    const { data } = await this.api.get<AppointmentStatsDto[]>(url);

    return data.map(({ lastCreated, ...rest }) => ({
      ...rest,
      lastCreated: lastCreated ? DateTime.fromISO(lastCreated) : undefined
    }));
  }

  public async getPracticeOutage(tenantId: guid): Promise<boolean> {
    const { data } = await this.api.get<boolean>(
      `/tenant/PracticeOutage/${tenantId}`
    );
    return data;
  }

  public async putPracticeOutage(
    tenantId: guid,
    practiceOutage: boolean
  ): Promise<boolean> {
    const { data } = await this.api.put<boolean>(
      `/tenant/PracticeOutage/${tenantId}`,
      null,
      { params: { practiceOutage } }
    );
    return data;
  }

  public async getProviders(tenantId: guid): Promise<Provider[]> {
    const { data } = await this.api.get<ProviderDto[]>(`/provider/${tenantId}`);
    return data.map(({ firstAvailable, lastAvailable, ...rest }) => ({
      ...rest,
      firstAvailable: firstAvailable
        ? DateTime.fromISO(firstAvailable)
        : undefined,
      lastAvailable: lastAvailable ? DateTime.fromISO(lastAvailable) : undefined
    }));
  }

  public async getSyncState(tenantId: guid): Promise<EntitySequence[]> {
    const { data } = await this.api.get<EntitySequenceDto[]>(
      `/syncstate/${tenantId}`
    );

    return data.map(es => ({
      ...es,
      updatedDate: es.updatedDate ? DateTime.fromISO(es.updatedDate) : undefined
    }));
  }

  public async postTtmSnapshot(tenantId: guid) {
    const { data } = await this.api.post("/snapshot", tenantId);
    return data;
  }
  public async postRecalculate(tenantId: guid) {
    const { data } = await this.api.post("/recalculate", tenantId);
    return data;
  }
}

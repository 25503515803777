import qs from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { DateTime } from "@bps/utils";
import { CurrentSiteVersionDto } from "@libs/api/gateways/sia/models/CurrentSiteVersion";
import { SiteComponentLogDto } from "@libs/api/gateways/sia/models/SiteComponentLog";
import {
  GetTenantsParams,
  InstallerDetail,
  ProsTenant,
  ReportsType,
  SIATenantDetails,
  SiteAvailableReport,
  SiteCommandUpdateArgs,
  SiteComponentType,
  SiteComponentVersionApproval,
  SiteComponentVersionDto,
  SiteServerReport,
  SiteVersionsDto,
  VersionApprovalRequest
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { ISiaOpsGateway } from "@libs/api/gateways/sia/sia-ops-gateway.interface";
import { getShortComponentTypeName } from "@libs/api/gateways/sia/sia-ops-gateway.utils";
import { guid } from "@libs/common/guid";

export class SiaOpsGateway implements ISiaOpsGateway {
  constructor(private api: AxiosInstance) {}

  async postSiteCommandUpdate(args: SiteCommandUpdateArgs): Promise<string> {
    const {
      tenantId,
      commandType,
      subCommandType,
      componentType,
      scheduled
    } = args;
    await this.api.post("site/siteCommandUpdate", {
      tenantId,
      commandType,
      subCommandType,
      componentType: componentType?.toUpperCase(),
      scheduled
    });
    return "OK";
  }

  async approveBuild(
    siteId: string,
    component: SiteComponentType,
    version: string
  ) {
    // Returns a 204
    await this.api.put("site/buildnumber", {
      componentType: component,
      desiredVersion: version,
      tenantId: siteId
    });

    return "OK";
  }

  async updateComponentState(
    siteId: string,
    state: string,
    componentType: SiteComponentType
  ): Promise<string> {
    const { data } = await this.api.put("site/component-state", {
      state,
      id: siteId,
      component: getShortComponentTypeName(componentType)
    });

    return data;
  }

  async getCurrentVersions(siteId: string): Promise<CurrentSiteVersionDto[]> {
    const url = qs.stringifyUrl({
      url: "site/currentVersion",
      query: {
        components: [
          SiteComponentType.SiteAgent,
          SiteComponentType.SiteManager
        ],
        tenantId: siteId
      }
    });

    const { data } = await this.api.get(url);
    return data;
  }

  async getVersions(
    siteId: string,
    desiredVersion: string
  ): Promise<SiteVersionsDto> {
    const url = qs.stringifyUrl({
      url: `site/versions/${desiredVersion}`,
      query: {
        id: siteId
      }
    });

    const { data } = await this.api.get<string>(url);
    const dataString = decodeURIComponent(data);
    try {
      return JSON.parse(dataString);
    } catch (e) {
      throw new Error(dataString);
    }
  }

  async getHealthCheck(siteId: string): Promise<string> {
    const { data } = await this.api.get(`site/healthCheck/${siteId}`);
    return data;
  }

  async uploadLogs(
    siteId: string,
    componentType: SiteComponentType
  ): Promise<string> {
    const component = getShortComponentTypeName(componentType);

    const { data } = await this.api.get(
      `site/log-upload/${component}/${siteId}`
    );
    return data;
  }

  async updateLogLevel(
    logLevel: string,
    siteId: string,
    componentType?: SiteComponentType
  ): Promise<string> {
    const component = componentType && getShortComponentTypeName(componentType);

    const { data } = await this.api.put("site/logLevel", {
      logLevel,
      id: siteId,
      component
    });
    return data;
  }

  async getSiteLogs(siteId: string): Promise<SiteComponentLogDto[]> {
    const { data } = await this.api.get(`site/logs/${siteId}`);
    return data;
  }

  async modifyPrOSDatabase(siteId: string, command: string) {
    const { data } = await this.api.post("site/modify-db", {
      siteId,
      databaseCommand: command
    });
    return data;
  }

  async getPing(
    siteId: string,
    componentType: SiteComponentType
  ): Promise<string> {
    const component = getShortComponentTypeName(componentType);

    const { data } = await this.api.get(`site/ping/${component}/${siteId}`);
    return data;
  }

  async getAvailableVersions(): Promise<SiteComponentVersionDto[]> {
    const url = qs.stringifyUrl({
      url: "site/versions",
      query: {
        components: [SiteComponentType.SiteAgent, SiteComponentType.SiteManager]
      }
    });

    const { data } = await this.api.get<SiteComponentVersionDto[]>(url);

    return data.map(({ versions, ...rest }) => ({
      versions: versions.map(({ createdDate, ...rest }) => ({
        createdDate: DateTime.fromISO(createdDate.toString()),
        ...rest
      })),
      ...rest
    }));
  }

  async getApprovedVersions(): Promise<SiteComponentVersionApproval[]> {
    const url = qs.stringifyUrl({
      url: "site/approveBuildVersion"
    });

    const { data } = await this.api.get<SiteComponentVersionApproval[]>(url);
    return data.map(({ createdDate, ...rest }) => ({
      createdDate: DateTime.fromISO(createdDate.toString()),
      ...rest
    }));
  }

  async postApprovedVersions({
    componentType,
    componentVersion
  }: VersionApprovalRequest): Promise<SiteComponentVersionApproval> {
    const url = qs.stringifyUrl({
      url: "site/approveBuildVersion"
    });

    const { data } = await this.api.post<SiteComponentVersionApproval>(url, {
      componentType,
      componentVersion
    });

    const { createdDate, ...rest } = data;

    return {
      createdDate: DateTime.fromISO(data.createdDate.toString()),
      ...rest
    };
  }

  async deleteApprovedVersions(approvalId: string): Promise<string> {
    const url = qs.stringifyUrl({
      url: "site/approveBuildVersion",
      query: {
        approvalId
      }
    });

    const { data } = await this.api.delete(url);
    return data;
  }

  async getSiteActivity(siteId: string): Promise<string> {
    const url = qs.stringifyUrl({
      url: "site/activity",
      query: {
        tenantId: siteId
      }
    });

    const { data } = await this.api.get(url);
    return data;
  }

  async getSiteReport(
    siteId: guid,
    componentType: SiteComponentType,
    report: string
  ): Promise<SiteServerReport> {
    const component = getShortComponentTypeName(componentType);

    const { data } = await this.api.get(
      `site/reports/${component}/${report}/${siteId}`
    );
    return JSON.parse(data);
  }

  async getSiteAvailableReports(
    siteId: guid,
    componentType: SiteComponentType,
    type: ReportsType
  ): Promise<SiteAvailableReport[]> {
    const component = getShortComponentTypeName(componentType);

    const { data } = await this.api.get(
      `site/reports/list/${component}/${type}/${siteId}`
    );
    return JSON.parse(data).Reports.map(r => ({ id: r.Action, name: r.Name }));
  }

  async checkUpdate(siteId: string): Promise<string> {
    const { data } = await this.api.post(`site/check-update/${siteId}`);
    return data;
  }

  async getTenants(params?: GetTenantsParams): Promise<ProsTenant[]> {
    const { data } = await this.api.get("tenants", {
      params
    });
    return data;
  }

  async getTenant(tenantId: guid): Promise<SIATenantDetails> {
    const { data } = await this.api.get(`tenants/tenant/${tenantId}`);
    return data;
  }

  async installPrerequisites(siteId: string): Promise<string> {
    const { data } = await this.api.post(
      `site/install-prerequisites/${siteId}`
    );
    return data;
  }

  async deleteEntityEvents(siteId: string): Promise<string> {
    const { data } = await this.api.post(`site/delete-entity-events/${siteId}`);
    return data;
  }

  async getSiteManagerInstallerDetail(): Promise<InstallerDetail> {
    const { data } = await this.api.get("site/sitemanager");
    return data;
  }

  async updateSiteComponentCredentials(tenantId: string): Promise<string> {
    const url = qs.stringifyUrl({
      url: "site/credentials",
      query: {
        tenantId
      }
    });

    const { data } = await this.api.put(url);
    return data;
  }
}

export interface SiteSyncStateDto {
  SequenceNumber: number | null;
  RecoveryModeDateUtc: string | null;
  LastSequenceNumberSent: number | null;
  LastSequenceNumberSentAtUtc: string | null;
  LastSequenceNumberReceived: number | null;
  LastSequenceNumberReceivedAtUtc: string | null;
  SequenceNumberProcessedAtUtc: string | null;
}

export class SiteSyncState {
  sequenceNumber: number | null;
  recoveryModeDateUtc: string | null;
  lastSequenceNumberSent: number | null;
  lastSequenceNumberSentAtUtc: string | null;
  lastSequenceNumberReceived: number | null;
  lastSequenceNumberReceivedAtUtc: string | null;
  sequenceNumberProcessedAtUtc: string | null;

  public get outOfSync() {
    return this.recoveryModeDateUtc != null;
  }

  constructor(dto: SiteSyncStateDto) {
    Object.assign(this, dto);
  }
}

import { ChangeLogDto } from "../../types/common-dtos";

import { DateTime } from "@bps/utils";

export interface MessageCreditsBalanceDto
  extends Omit<MessageCreditsBalance, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface MessageCreditsEventDto
  extends Omit<MessageCreditsEvent, "changeLog"> {
  changeLog: ChangeLogDto;
}

export interface AddMessageCreditsEventDto
  extends Omit<MessageCreditsEventDto, "id" | "source" | "changeLog"> {}

export interface MessageCreditsBalance {
  id: string;
  tenantId: string;
  units: number;
  source?: string;
  details?: string;
  eventId?: string;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
}

export interface MessageCreditsEvent {
  id: string;
  tenantId: string;
  units: number;
  source?: string;
  details?: string;
  changeLog: {
    createdDate?: DateTime;
    createdBy?: string;
    updatedDate?: DateTime;
    updatedBy?: string;
  };
}

export interface OutboundSmsAuditDto {
  id: string;
  outboundSmsId: string;
  content: OutboundSmsAuditContentDto;
  vendorCode: SmsVendor;
  changeLog: ChangeLogDto;
  outboundSmsAuditType: OutboundSmsAuditType;
}

export interface OutboundEmailAuditDto {
  id: string;
  sourceTenantId: string;
  sourceId: string;
  subject: string;
  to: string;
  etag: string;
  changeLog: ChangeLogDto;
}

export interface OutboundEmailAuditArgs {
  searchTerm?: string;
  startDate?: Date;
  endDate?: Date;
}

interface OutboundSmsAuditContentDto {
  request: {
    body: string;
  };
  response: {
    body: string;
    statusCode: string;
  };
}
export interface OutboundSmsAuditArgs {
  phone?: string;
  tenantId?: string;
  fromDate?: Date;
  toDate?: Date;
}

export enum OutboundSmsAuditType {
  SmsRequest = "SMSREQUEST",
  SmsCallback = "SMSCALLBACK"
}

export interface CreateOutboundSmsRequestArgs {
  sourceTenantId: string;
  phone: string;
  text: string;
}

export interface OutboundSmsRequest {
  id: string;
  phone: string;
  text: string;
  sourceTenantId: string;
  sourceId: string;
}

export interface CreateOutboundEmailRequestArgs {
  sourceTenantId: string;
  to: string;
  subject: string;
  body: string;
}

export interface OutboundEmailRequest {
  id: string;
  to: string;
  subject: string;
  body: string;
  sourceTenantId: string;
  sourceId: string;
}

enum SmsVendor {
  MessageMedia = "MessageMedia"
}

// Source: https://support.messagemedia.com/hc/en-us/articles/4413635760527-Messaging-API
export const MessageMediaDeliveryReportErrorCodeMap = {
  "101": "Message being processed by the gateway.",
  "102":
    "Message is being rerouted to a different provider after failing via the first provider.",
  "151": "Message held for screening.",
  "200": "Message submitted to downstream provider for delivery.",
  "210": "Message accepted by downstream provider.",
  "211": "Message is enroute for delivery by provider.",
  "212": "Message submitted. Delivery pending.",
  "213": "Message scheduled for delivery by downstream provider.",
  "220": "Message delivered.",
  "221": "Message delivered to the handset.",
  "320": "Message validity period has expired (prior to submission).",
  "401": "Message validity period has expired (before delivery).",
  "301": "Usage threshold reached. Message discarded.",
  "302": "Destination address blocked. Message discarded.",
  "303": "Source address blocked. Message discarded.",
  "304": "Message dropped. Contact support.",
  "305": "Message discarded due to duplicate detection.",
  "402": "Message rejected by downstream provider.",
  "403": "Message skipped by downstream provider.",
  "410": "Invalid source address.",
  "411": "Invalid destination address.",
  "412": "Destination address blocked.",
  "413": "SMS service unavailable on destination.",
  "414": "Destination unreachable.",
  "330": "Gateway failure.",
  "331": "Message discarded.",
  "332": "No available route to destination.",
  "333": "Source address unsupported for this destination.",
  "400": "Message failed; undeliverable.",
  "405": "Message cancelled or deleted by provider."
};

import { useRootStore } from "@stores/StoresProvider";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { Permissions } from "@libs/permissions/permissions.enum";
import { GetRolesArgs } from "./env-gateway.inferface";
import {
  EnvRoleDto,
  EnvRoleImpersonation,
  EnvUser,
  NewEnvUser
} from "@libs/api/gateways/env/env-gateway.dtos";
import { cacheUpsertSingleItemInArray } from "@libs/react-query/react-query-cache.utils";
import { verifyHasPermissions } from "@libs/permissions/permissions.utils";
import { useGateways } from "@libs/api/gateways-context";
import { HttpError } from "@bps/http-client";

const CacheKeys = {
  User: "env-user",
  Users: "env-users",
  Roles: "env-roles"
};

type PermissionsCheckQueryResult = Omit<
  ReturnType<typeof useCurrentUserQuery>,
  "data"
> & { data: boolean | undefined };

export const useCurrentUserQuery = () => {
  const { envGateway } = useGateways();

  return useQuery<EnvUser>(CacheKeys.User, envGateway.getCurrentUser, {
    cacheTime: Infinity
  });
};

export const useCurrentUserImpersonation = () => {
  const queryClient = useQueryClient();
  const { data } = useCurrentUserQuery();
  const { data: allRoles } = useEnvAllRoles();

  return (value: string | undefined) => {
    const activeRole = allRoles?.find(r => r.code === value);
    const impersonationPermissions = activeRole
      ? (activeRole.permissions as Permissions[])
      : [];

    const newRole = activeRole
      ? ({
          role: value,
          permissions: impersonationPermissions
        } as EnvRoleImpersonation)
      : undefined;

    queryClient.setQueryData<EnvUser>(CacheKeys.User, {
      ...data,
      impersonatingRole: newRole
    });
  };
};

export const useUsersQuery = () => {
  const { envGateway } = useGateways();
  return useQuery<EnvUser[]>(CacheKeys.Users, envGateway.getUsers);
};

export const useUpsertUserMutation = () => {
  const { envGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    EnvUser,
    unknown,
    { user: EnvUser | NewEnvUser; isCreate: boolean }
  >(
    ({ user, isCreate }) =>
      isCreate ? envGateway.createUser(user) : envGateway.updateUser(user),
    {
      onSuccess: dto => {
        // We need to update the item that was modified
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [CacheKeys.Users, dto.id],
          item: dto
        });

        // To refresh the view, we need to invalidate the list query data as well
        queryClient.invalidateQueries(CacheKeys.Users);

        feedback.success("User has been updated successfully!");
      }
    }
  );
};

export const usePermissionsCheckQuery = (
  permissions: Permissions | Permissions[] = [],
  operator: "and" | "or" = "and"
): PermissionsCheckQueryResult => {
  const { data, ...query } = useCurrentUserQuery();

  if (!data) {
    return {
      data: undefined,
      ...query
    };
  }

  const hasPermissions = verifyHasPermissions(
    data.impersonatingRole
      ? data.impersonatingRole.permissions
      : data.permissions,
    permissions,
    operator
  );

  return {
    data: hasPermissions,
    ...query
  };
};

export const useEnvRoles = (args?: GetRolesArgs) => {
  const { envGateway } = useGateways();
  return useQuery<EnvRoleDto[]>(
    CacheKeys.Roles,
    () => envGateway.getRoles(args),
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

export const useEnvAllRoles = () => {
  const { envGateway } = useGateways();
  return useQuery<EnvRoleDto[], HttpError>(
    CacheKeys.Roles,
    () => envGateway.getAllRoles(),
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

import { ChangeLogDto } from "@libs/api/types/common-dtos";

export interface ApplicationDto {
  id: string;
  name: string;
  isEnabled: boolean;
  ipRanges?: IpRangeDto[];
  changeLog: ChangeLogDto;
}

export interface ApplicationCloudApplicationDto {
  id: string;
  name: string;
  isEnabled: boolean;
  ipRanges?: IpRangeDto[];
  cloudApplication: CloudApplicationDto;
  changeLog: ChangeLogDto;
}

export interface CloudApplicationScopeDto {
  id: string;
  value: string;
  description: string;
}

export interface CloudApplicationDto {
  id: string;
  tenantId: string;
  secrets: CloudApplicationSecretDto[];
  scopes: string[];
}

export interface CloudApplicationSecretDto {
  id: string;
  secretText: string;
  displayName: string;
  startDateTime?: string;
  endDateTime?: string;
}

export interface IpRangeDto {
  ipRangeBegin?: string;
  ipRangeEnd?: string;
  isInactive: boolean;
}

export interface ApplicationArgs {
  applicationId: string;
  tenantId: string;
}

export interface CloudApplicationScopeUpdateRequest {
  applicationId: string;
  tenantId: string;
  scopes: string[];
}

export interface CloudApplicationSecretCreateRequest {
  applicationId: string;
  tenantId: string;
  secret: CloudApplicationSecret;
}

export interface CloudApplicationSecretDeleteRequest {
  applicationId: string;
  tenantId: string;
  id: string;
}

export interface CloudApplicationSecret {
  displayName: string;
  startDateTime: Date;
  endDateTime: Date;
}

export interface ApplicationCreateRequest {
  tenantId: string;
  name: string;
  ipRanges?: IpRangeDto[];
}

export interface ApplicationUpdateRequest {
  applicationId: string;
  tenantId: string;
  name: string;
  isEnabled: boolean;
  ipRanges?: IpRangeDto[];
}

export interface CallbackTypesDto {
  code: string;
  description?: string;
  ipRanges?: IpRangeDto[];
  changeLog: ChangeLogDto;
}

export interface CallbackTypesUpdateRequest {
  code: string;
  description?: string;
  ipRanges?: IpRangeDto[];
}

export interface CertificateDto {
  id: string;
  content: string;
  isDeployedToApimAppGateway: boolean;
  metadata?: CertificateMetadataDto;
  changeLog: ChangeLogDto;
}

export interface CertificateMetadataDto {
  friendlyName: string;
  subject: string;
  issuer: string;
  notAfter: string;
  notBefore: string;
  keyExchangeAlgorithm: string;
  version: string;
}

export interface CertificateCreateRequest {
  tenantId: string;
  content: string;
}

export interface CertificateUpdateRequest {
  id: string;
  tenantId: string;
  content: string;
}

export interface CertificateIdentifier {
  id: string;
  tenantId: string;
}

export interface TenantSettingDto {
  value?: TenantSettingValueDto;
  changeLog: ChangeLogDto;
}

export interface TenantSettingValueDto {
  allowCertificateFromTenantId?: string;
}

export interface TenantSettingUpdateRequest {
  tenantId: string;
  allowCertificateFromTenantId?: string;
}

export interface ConsentDto {
  id: string;
  applicationId: string;
  isEnabled: boolean;
  hasAllLocationAccess: boolean;
  locations: string[];
  changeLog: ChangeLogDto;
}

export interface ConsentLocation {
  locationId: string;
  locationName?: string;
}

export interface ConsentPatchRequest {
  locations: string[];
  tenantId: string;
  id: string;
}

export interface TenantConsentArgs {
  tenantConsentId: string;
  tenantId: string;
}

export enum HierarchyTypes {
  Location = "L",
  Practice = "P",
  Department = "D",
  Group = "G"
}

export interface ConsentCreateRequest {
  tenantId: string;
  applicationId: string;
  hasAllLocationAccess: boolean;
  isEnabled: boolean;
}

export interface ConsentUpdateRequest {
  tenantId: string;
  applicationId: string;
  hasAllLocationAccess: boolean;
  isEnabled: boolean;
  tenantConsentId: string;
}

export interface CallbackDto {
  tenantId: string;
  id: string;
  key: string;
  type: string;
  isEnabled: boolean;
  locationId: string;
  userId: string;
  propertyBag: string;
  changeLog: ChangeLogDto;
}

export interface CallbackCreateRequest {
  tenantId: string;
  type: string;
  key: string;
  locationId: string;
  userId: string;
  propertyBag: string;
  isEnabled: boolean;
}

export interface CallbackUpdateRequest {
  id: string;
  tenantId: string;
  type: string;
  key: string;
  locationId: string;
  userId: string;
  propertyBag: string;
  isEnabled: boolean;
}

export interface CallbackFilterRequest {
  tenantId: string;
  key?: string;
  isEnabled?: boolean;
}

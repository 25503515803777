import { useRootStore } from "@stores/StoresProvider";
import {
  cacheDeleteSingleItemInArray,
  cacheUpsertSingleItemInArray
} from "@libs/react-query/react-query-cache.utils";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";

import { OutboundEmailAudit } from "./models/OutboundEmailAudit";
import { OutboundSmsAudit } from "./models/OutboundSmsAudit";

import { HttpError } from "@bps/http-client";
import { PagingResponse } from "@libs/paging/paging-response.type";
import {
  AddMessageCreditsEventDto,
  CreateOutboundEmailRequestArgs,
  CreateOutboundSmsRequestArgs,
  MessageCreditsBalance,
  MessageCreditsEvent,
  OutboundEmailAuditArgs,
  OutboundEmailRequest,
  OutboundSmsAuditArgs,
  OutboundSmsRequest
} from "@libs/api/gateways/plt-comms/plt-comms-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const PltCommsCacheKeys = {
  MessageCreditsEvents: "message-credits-events",
  MessageCreditsBalances: "message-credits-balances",
  OutboundSmsAudit: "outbound-sms-audit",
  OutboundEmailAudit: "outbound-email-audit"
};

// MessageCreditsEvents/Balances

export const useMessageCreditsBalances = (tenantId: string) => {
  const { pltCommsOpsGateway } = useGateways();
  return useQuery<MessageCreditsBalance[]>(
    [PltCommsCacheKeys.MessageCreditsBalances, tenantId],
    async () => {
      return await pltCommsOpsGateway.getMessageCreditsBalances(tenantId);
    }
  );
};

export const useMessageCreditsEvents = (tenantId: string) => {
  const { pltCommsOpsGateway } = useGateways();
  return useQuery<MessageCreditsEvent[]>(
    [PltCommsCacheKeys.MessageCreditsEvents, tenantId],
    () =>
      pltCommsOpsGateway.getMessageCreditsEvents(
        tenantId
        // , filter
      )
  );
};

export const useAddMessageCreditsEvent = () => {
  const { pltCommsOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<MessageCreditsEvent, HttpError, AddMessageCreditsEventDto>(
    pltCommsOpsGateway.addMessageCreditsEvents,
    {
      onSuccess: async dto => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCommsCacheKeys.MessageCreditsEvents, dto.tenantId],
          item: dto
        });

        //would prefer to Delete/Clear & Refetch Balances
        cacheDeleteSingleItemInArray({
          queryClient,
          queryKey: [PltCommsCacheKeys.MessageCreditsBalances, dto.tenantId],
          id: dto.tenantId
        });
        feedback.success(
          "A new message credits event has been added successfully!"
        );
      }
    }
  );
};

export const useOutboundEmailAuditQuery = (args?: OutboundEmailAuditArgs) => {
  const { pltCommsOpsGateway } = useGateways();
  const { feedback } = useRootStore();

  return useQuery<PagingResponse<OutboundEmailAudit>>(
    [PltCommsCacheKeys.OutboundEmailAudit, args],
    async () => {
      // Only send request for emails if we have started to input filters
      if (!args) {
        return {} as PagingResponse<OutboundEmailAudit>;
      }
      return await pltCommsOpsGateway.getOutboundEmailAudits(args);
    },
    {
      onError: (error: Error) => {
        feedback.error(error.message.replace(/\/n/g, " "));
      }
    }
  );
};

export const useOutboundSmsAuditQuery = (args: OutboundSmsAuditArgs) => {
  const { pltCommsOpsGateway } = useGateways();

  return useInfiniteQuery<PagingResponse<OutboundSmsAudit>, HttpError>(
    [PltCommsCacheKeys.OutboundSmsAudit, args],
    async page => {
      return await pltCommsOpsGateway.getOutboundSmsAudits(
        args,
        page.pageParam ?? 1
      );
    },
    {
      getNextPageParam: lastPage => lastPage.next
    }
  );
};

export const useCreateOutboundSmsRequest = () => {
  const { pltCommsOpsGateway } = useGateways();
  return useMutation<
    OutboundSmsRequest,
    HttpError,
    CreateOutboundSmsRequestArgs
  >(pltCommsOpsGateway.createOutboundSmsRequest);
};

export const useCreateOutboundEmailRequest = () => {
  const { pltCommsOpsGateway } = useGateways();
  return useMutation<
    OutboundEmailRequest,
    HttpError,
    CreateOutboundEmailRequestArgs
  >(pltCommsOpsGateway.createOutboundEmailRequest);
};

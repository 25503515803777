import { AxiosInstance } from "@bps/http-client";
import qs from "query-string";

import {
  ApplicationArgs,
  ApplicationCloudApplicationDto,
  ApplicationCreateRequest,
  ApplicationDto,
  ApplicationUpdateRequest,
  CallbackCreateRequest,
  CallbackDto,
  CallbackTypesDto,
  CallbackTypesUpdateRequest,
  CallbackUpdateRequest,
  CertificateCreateRequest,
  CertificateDto,
  CertificateIdentifier,
  CertificateUpdateRequest,
  CloudApplicationScopeDto,
  CloudApplicationScopeUpdateRequest,
  CloudApplicationSecretCreateRequest,
  CloudApplicationSecretDeleteRequest,
  CloudApplicationSecretDto,
  ConsentCreateRequest,
  ConsentDto,
  ConsentPatchRequest,
  ConsentUpdateRequest,
  TenantConsentArgs,
  TenantSettingDto,
  TenantSettingUpdateRequest
} from "./plt-integrators-gateway.dtos";
import { IPlatformIntegratorsGateway } from "./plt-integrators-gateway.interface";

export class PlatformIntegratorsGateway implements IPlatformIntegratorsGateway {
  constructor(private api: AxiosInstance) {}

  getApplications = async (tenantId: string) => {
    const url = qs.stringifyUrl({
      url: `tenants/${tenantId}/applications`
    });

    const { data } = await this.api.get<ApplicationDto[]>(url);

    return data;
  };

  getApplication = async (args: ApplicationArgs) => {
    const url = qs.stringifyUrl({
      url: `tenants/${args.tenantId}/applications/${args.applicationId}`
    });

    const { data } = await this.api.get<ApplicationCloudApplicationDto>(url);

    return data;
  };
  getGatewayScopes = async () => {
    const url = qs.stringifyUrl({
      url: "integratorGateway/definedScopes"
    });

    const { data } = await this.api.get<CloudApplicationScopeDto[]>(url);

    return data;
  };

  updateApplicationScopes = async (
    request: CloudApplicationScopeUpdateRequest
  ) => {
    return (
      await this.api.put<string[]>(
        `tenants/${request.tenantId}/applications/${request.applicationId}/scopes`,
        request.scopes
      )
    ).data;
  };

  createApplicationSecret = async (
    request: CloudApplicationSecretCreateRequest
  ) => {
    return (
      await this.api.post<CloudApplicationSecretDto>(
        `tenants/${request.tenantId}/applications/${request.applicationId}/secrets`,
        request.secret
      )
    ).data;
  };

  deleteApplicationSecret = async (
    request: CloudApplicationSecretDeleteRequest
  ) => {
    return (
      await this.api.delete<void>(
        `tenants/${request.tenantId}/applications/${request.applicationId}/secrets/${request.id}`
      )
    ).data;
  };

  createApplication = async (request: ApplicationCreateRequest) => {
    return (
      await this.api.post<ApplicationDto>(
        `tenants/${request.tenantId}/applications`,
        request
      )
    ).data;
  };

  getCallbackTypes = async (): Promise<CallbackTypesDto[]> => {
    const result = await this.api.get<CallbackTypesDto[]>("/callbackTypes");
    return result.data;
  };

  patchApplication = async (request: ApplicationUpdateRequest) => {
    return (
      await this.api.put<ApplicationDto>(
        `tenants/${request.tenantId}/applications/${request.applicationId}`,
        request
      )
    ).data;
  };

  patchCallbackTypes = async (request: CallbackTypesUpdateRequest) => {
    return (
      await this.api.put<CallbackTypesDto>(
        `/callbackTypes/${request.code}`,
        request
      )
    ).data;
  };

  getCertificates = async (tenantId: string) => {
    const { data } = await this.api.get<CertificateDto[]>(
      `tenants/${tenantId}/certificates`
    );

    return data;
  };

  getCertificate = async (request: CertificateIdentifier) => {
    const { data } = await this.api.get<CertificateDto>(
      `tenants/${request.tenantId}/certificates/${request.id}`
    );

    return data;
  };

  createCertificate = async (request: CertificateCreateRequest) => {
    return (
      await this.api.post<CertificateDto>(
        `tenants/${request.tenantId}/certificates`,
        request
      )
    ).data;
  };

  updateCertificate = async (request: CertificateUpdateRequest) => {
    return (
      await this.api.put<CertificateDto>(
        `tenants/${request.tenantId}/certificates/${request.id}`,
        request
      )
    ).data;
  };

  deleteCertificate = async (request: CertificateIdentifier) => {
    return (
      await this.api.delete<void>(
        `tenants/${request.tenantId}/certificates/${request.id}`
      )
    ).data;
  };

  deployCertificateToGateway = async (request: CertificateIdentifier) => {
    return (
      await this.api.post<void>(
        `tenants/${request.tenantId}/certificates/${request.id}/$deployToGateway`
      )
    ).data;
  };

  removeCertificateFromGateway = async (request: CertificateIdentifier) => {
    return (
      await this.api.post<void>(
        `tenants/${request.tenantId}/certificates/${request.id}/$removeFromGateway`
      )
    ).data;
  };

  updateTenantSetting = async (request: TenantSettingUpdateRequest) => {
    return (
      await this.api.put<CertificateDto>(
        `tenants/${request.tenantId}/settings`,
        {
          value: {
            allowCertificateFromTenantId: request.allowCertificateFromTenantId
          }
        }
      )
    ).data;
  };

  getTenantSetting = async (tenantId: string) => {
    const { data } = await this.api.get<TenantSettingDto>(
      `tenants/${tenantId}/settings`
    );

    return data;
  };

  getConsents = async (tenantId: string) => {
    const { data } = await this.api.get<ConsentDto[]>(
      `tenants/${tenantId}/consents`
    );
    return data;
  };

  getConsent = async (args: TenantConsentArgs) => {
    const { data } = await this.api.get<ConsentDto>(
      `tenants/${args.tenantId}/consents/${args.tenantConsentId}`
    );
    return data;
  };

  patchTenantConsentLocation = async (request: ConsentPatchRequest) => {
    return (
      await this.api.patch<ConsentDto>(
        `tenants/${request.tenantId}/consents/${request.id}`,
        {
          locations: request.locations
        }
      )
    ).data;
  };

  createTenantConsent = async (request: ConsentCreateRequest) => {
    return (
      await this.api.post<ConsentDto>(
        `tenants/${request.tenantId}/consents`,
        request
      )
    ).data;
  };

  updateTenantConsent = async (request: ConsentUpdateRequest) => {
    return (
      await this.api.put<ConsentDto>(
        `tenants/${request.tenantId}/consents/${request.tenantConsentId}`,
        request
      )
    ).data;
  };

  getCallbacks = async (tenantId: string): Promise<CallbackDto[]> => {
    const url = qs.stringifyUrl({
      url: `tenants/${tenantId}/callbacks`,
      query: {
        tenantId
      }
    });
    return (await this.api.get<CallbackDto[]>(url)).data;
  };

  createCallback = async (request: CallbackCreateRequest) => {
    return (
      await this.api.post<CallbackDto>(
        `tenants/${request.tenantId}/callbacks`,
        request
      )
    ).data;
  };

  updateCallback = async (request: CallbackUpdateRequest) => {
    return (
      await this.api.put<CallbackDto>(
        `tenants/${request.tenantId}/callbacks/${request.id}`,
        request
      )
    ).data;
  };
}

import { QueryClient, QueryKey } from "react-query";

type UpsertOptions<TItem> = {
  queryClient: QueryClient; // React query client instance
  queryKey: QueryKey; // The key that points to the collection within the react-query cache
  item: TItem; // Item to be inserted
  isIdentityEqual?: (other: TItem) => boolean; // Function which checks if the identity of an existing item is equal to
  asFirstItem?: boolean;
};

type DeleteOptions<TItem> = Omit<UpsertOptions<TItem>, "item"> & {
  id: string; // Item id to be deleted
};

/**
 * Updates an array of items in the cache, provided the current state of those items is 'success'.
 *
 * @param queryClient React query client instance
 * @param queryKey The key that points to the collection within the react-query cache
 * @param applyUpdate The update function
 */
export const cacheUpdateItemArray = <T>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  applyUpdate: (items: T[]) => T[]
) => {
  const queryState = queryClient.getQueryState<T[]>(queryKey);
  if (queryState?.status !== "success") return;

  const data = queryState.data ?? [];
  queryClient.setQueryData<T[]>(queryKey, applyUpdate(data));
};

/**
 * Inserts or updates a single item in a cached array of items. If the cached state
 * is not 'success' then no upsert will be performed.
 *
 * the identity of the item to be updated. Defaults to `other => other["id"] === item["id"]`
 * @param options
 */
export const cacheUpsertSingleItemInArray = <TItem>(
  options: UpsertOptions<TItem>
) => {
  const { queryClient, queryKey, item, isIdentityEqual } = options;
  if (!item) return;

  cacheUpdateItemArray<TItem>(queryClient, queryKey, prev => {
    const checkIdentity =
      isIdentityEqual ?? ((other: TItem) => item["id"] === other["id"]);

    let hasUpdated = false;

    const next = prev?.map(prevItem => {
      if (checkIdentity(prevItem)) {
        hasUpdated = true;
        return item;
      }

      return prevItem;
    });

    if (!hasUpdated) {
      if (!options.asFirstItem) {
        next.push(item);
      } else {
        next.unshift(item);
      }
    }

    return next;
  });
};

/**
 * Deletes a single item from a cached array of items. If the cached state is not 'success'
 * then this is a no-op.
 *
 * the identity of the item to be updated. Defaults to `other => other["id"] === item["id"]`
 * @param options
 */
export const cacheDeleteSingleItemInArray = <TItem>(
  options: DeleteOptions<TItem>
) => {
  const { queryClient, queryKey, id, isIdentityEqual } = options;

  cacheUpdateItemArray<TItem>(queryClient, queryKey, prev => {
    const checkIdentity =
      isIdentityEqual ?? ((other: TItem & { id: string }) => other.id === id);

    return prev.filter(item => !checkIdentity(item));
  });
};

import { CurrentSiteVersion } from "@libs/api/gateways/sia/models/CurrentSiteVersion";

import { guid } from "@libs/common/guid";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DateTime } from "@bps/utils";

import {
  GetTenantsParams,
  InstallerDetail,
  LogLevels,
  ProsDatabaseCommands,
  ProsTenant,
  ReportsType,
  SIATenantDetails,
  SiteAvailableReport,
  SiteComponentState,
  SiteComponentType,
  SiteComponentVersionApproval,
  SiteComponentVersionDto,
  SiteServerReport,
  SiteVersions,
  VersionApprovalRequest
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { SiteComponentLogDto } from "@libs/api/gateways/sia/models/SiteComponentLog";
import { PremSettingsUpdate } from "@libs/api/gateways/tg/tg-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const SiaCacheKeys = {
  AvailableComponentVersions: "pros-available-component-versions",
  SiteAvailableReports: "pros-site-available-reports",
  SiteComponentVersions: "pros-site-component-versions",
  ComponentVersions: "pros-component-versions",
  SiteVersions: "pros-site-versions",
  SiteLogs: "pros-site-logs",
  SiteServerReport: "pros-site-server-report",
  SiteManagerInstallerDetail: "site-manager-installer-detail",
  PremSetting: "pros-prem-settings",
  SIATenant: "sia-tenant",
  ApprovedComponentVersions: "approved-component-versions"
};
interface SiteArgs {
  siteId: guid;
}

export interface SiteDatabaseCommandArgs extends SiteArgs {
  command: ProsDatabaseCommands;
}

export interface PremSettingsArgs extends SiteArgs {
  updates: PremSettingsUpdate[];
}

export interface SiteComponentArgs extends SiteArgs {
  componentType: SiteComponentType;
}

export interface SiteCommandUpdateArgs {
  tenantId: string;
  commandType: string;
  subCommandType?: string;
  componentType: string;
  scheduled?: DateTime;
}

export interface SiteComponentLogLevelArgs extends SiteComponentArgs {
  logLevel: LogLevels;
}

export interface SiteComponentStateArgs extends SiteComponentArgs {
  state: SiteComponentState;
}

export interface SiteComponentVersionArgs extends SiteArgs {
  component: SiteComponentType;
  version: string;
}

// ----- QUERIES ---------------------------------------------------------------

export const useApprovedComponentVersionsQuery = () => {
  const { siaOpsGateway } = useGateways();

  return useQuery<SiteComponentVersionApproval[]>(
    [SiaCacheKeys.ApprovedComponentVersions],
    () => {
      return siaOpsGateway.getApprovedVersions();
    }
  );
};

export const useAvailableComponentVersionsQuery = () => {
  const { siaOpsGateway } = useGateways();

  return useQuery<SiteComponentVersionDto[]>(
    [SiaCacheKeys.AvailableComponentVersions],
    () => {
      return siaOpsGateway.getAvailableVersions();
    }
  );
};

export const useSiteActivity = (siteId: guid) => {
  const { siaOpsGateway } = useGateways();

  return useQuery<string>(
    [SiaCacheKeys.AvailableComponentVersions, siteId],
    async () => {
      return await siaOpsGateway.getSiteActivity(siteId);
    }
  );
};

export const useSiteComponentVersionsQuery = (siteId: guid) => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<CurrentSiteVersion[]>(
    [SiaCacheKeys.SiteComponentVersions, siteId],
    async () => {
      const dtos = await siaOpsGateway.getCurrentVersions(siteId);
      return dtos.map(dto => new CurrentSiteVersion(dto));
    },
    {
      onSuccess: data => {
        const componentData = {};
        for (const component of data) {
          if (!componentData[component.componentType]) {
            componentData[component.componentType] = {};
          }
          if (component.version?.length) {
            componentData[component.componentType][component.version] = true;
          }
        }
        for (const componentType of Object.keys(componentData)) {
          const versions = Object.keys(componentData[componentType]);
          queryClient.setQueryData(
            [SiaCacheKeys.ComponentVersions, componentType],
            (prev: string[]) =>
              [...new Set([...(prev || []), ...versions])].sort().reverse()
          );
        }
      }
    }
  );
};

export const useComponentVersions = (componentType?: SiteComponentType) => {
  return useQuery<string[]>(
    [SiaCacheKeys.ComponentVersions, componentType],
    async () => [],
    { keepPreviousData: true }
  );
};

export const useSiteVersionsQuery = (siteId: guid, desiredVersion?: string) => {
  const { siaOpsGateway } = useGateways();
  return useQuery<SiteVersions>(
    [SiaCacheKeys.SiteVersions, siteId],
    async () => {
      const data = await siaOpsGateway.getVersions(
        siteId,
        desiredVersion || "unknown"
      );
      return {
        ...data,
        ResponseDateTimeUTC: data?.ResponseDateTimeUTC
          ? DateTime.fromISO(data.ResponseDateTimeUTC)
          : undefined
      };
    },
    {
      enabled: !!desiredVersion
    }
  );
};

export const useLogFilesQuery = (siteId: guid) => {
  const { siaOpsGateway } = useGateways();

  return useQuery<SiteComponentLogDto[]>(
    [SiaCacheKeys.SiteLogs, siteId],
    async () => await siaOpsGateway.getSiteLogs(siteId)
  );
};

export const useSiteAvailableReportsQuery = (
  siteId: guid,
  component: SiteComponentType,
  options: { type: ReportsType; forceRefresh?: boolean }
) => {
  const { type, forceRefresh } = options;
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  if (forceRefresh) {
    queryClient.removeQueries([SiaCacheKeys.SiteAvailableReports, siteId]);
  }

  return useQuery<SiteAvailableReport[]>(
    [SiaCacheKeys.SiteAvailableReports, siteId, component, type],
    async () =>
      await siaOpsGateway.getSiteAvailableReports(siteId, component, type)
  );
};

export const useSiteReportQuery = (
  siteId: guid,
  component: SiteComponentType,
  options: { report: string; enabled?: boolean }
) => {
  const { report, enabled = true } = options;
  const { siaOpsGateway } = useGateways();

  return useQuery<SiteServerReport>(
    [SiaCacheKeys.SiteServerReport, siteId, report],
    async () => await siaOpsGateway.getSiteReport(siteId, component, report),
    {
      enabled
    }
  );
};

export const useTenantsQuery = (params?: GetTenantsParams) => {
  const { siaOpsGateway } = useGateways();
  return useQuery<ProsTenant[]>(
    [SiaCacheKeys.SiteLogs, params],
    async () => await siaOpsGateway.getTenants(params)
  );
};

export const useSIATenantQuery = (tenantId: guid) => {
  const { siaOpsGateway } = useGateways();
  return useQuery<SIATenantDetails>(
    [SiaCacheKeys.SIATenant, tenantId],
    async () => await siaOpsGateway.getTenant(tenantId)
  );
};

export const useSiteManagerInstallerDetailQuery = () => {
  const { siaOpsGateway } = useGateways();
  return useQuery<InstallerDetail>(
    [SiaCacheKeys.SiteManagerInstallerDetail],
    async () => await siaOpsGateway.getSiteManagerInstallerDetail()
  );
};

// ----- MUTATIONS WITH CACHE EXPIRY -------------------------------------------
export const useUpdateSiteComponentVersionMutation = () => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<string, Error, SiteComponentVersionArgs>(
    ({ siteId, component, version }) => {
      return siaOpsGateway.approveBuild(siteId, component, version);
    },
    {
      onSuccess: async (
        response: string,
        { siteId, component, version }: SiteComponentVersionArgs
      ) => {
        queryClient.setQueryData<CurrentSiteVersion[]>(
          [SiaCacheKeys.SiteComponentVersions, siteId],
          prev => {
            if (!prev) return [];

            return prev.map(currentVersion => {
              if (currentVersion.componentType !== component) {
                return currentVersion;
              }

              return new CurrentSiteVersion({
                component,
                version: currentVersion.version,
                desiredVersion: version || null
              });
            });
          }
        );
        if (component === SiteComponentType.SiteAgent) {
          queryClient.removeQueries([SiaCacheKeys.SiteVersions, siteId]);
        }
      },
      onError: e => {
        throw e;
      }
    }
  );
};

export const useLogFilesUploadMutation = () => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<string, Error, SiteComponentArgs>(
    ({ siteId, componentType }) =>
      siaOpsGateway.uploadLogs(siteId, componentType),
    {
      onSuccess: async (response: string, { siteId }: SiteComponentArgs) => {
        await queryClient.invalidateQueries([SiaCacheKeys.SiteLogs, siteId]);
      }
    }
  );
};

export const useApprovedComponentVersionsMutation = () => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();
  return useMutation<
    SiteComponentVersionApproval,
    Error,
    VersionApprovalRequest
  >(request => siaOpsGateway.postApprovedVersions(request), {
    onSuccess: async () => {
      await queryClient.refetchQueries([
        SiaCacheKeys.ApprovedComponentVersions
      ]);
    }
  });
};

export const useDeleteComponentVersionsMutation = () => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();
  return useMutation<string, Error, string>(
    approvalId => siaOpsGateway.deleteApprovedVersions(approvalId),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          SiaCacheKeys.ApprovedComponentVersions
        ]);
      }
    }
  );
};

// ----- PLAIN MUTATIONS -------------------------------------------------------
export const useHealthCheckMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    siaOpsGateway.getHealthCheck(siteId)
  );
};

export const usePingMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, SiteComponentArgs>(
    ({ siteId, componentType }) => siaOpsGateway.getPing(siteId, componentType)
  );
};

export const useSiteCommandUpdateMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, SiteCommandUpdateArgs>(
    ({ tenantId, commandType, componentType, subCommandType, scheduled }) =>
      siaOpsGateway.postSiteCommandUpdate({
        tenantId,
        commandType,
        componentType,
        subCommandType,
        scheduled
      })
  );
};

export const useLogLevelUpdateMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, SiteComponentLogLevelArgs>(
    async ({ siteId, componentType, logLevel }) => {
      return await siaOpsGateway.updateLogLevel(
        logLevel.toString(),
        siteId,
        componentType
      );
    }
  );
};

export const useComponentStateUpdateMutation = () => {
  const { siaOpsGateway } = useGateways();
  const queryClient = useQueryClient();
  return useMutation<string, Error, SiteComponentStateArgs>(
    ({ siteId, state, componentType }) => {
      return siaOpsGateway.updateComponentState(siteId, state, componentType);
    },
    {
      onSuccess: async (
        response: string,
        { siteId, state, componentType }: SiteComponentStateArgs
      ) => {
        if (state === SiteComponentState.Update) {
          queryClient.setQueryData<CurrentSiteVersion[]>(
            [SiaCacheKeys.SiteComponentVersions, siteId],
            prev => {
              if (!prev) return [];
              return prev.map(currentVersion => {
                if (currentVersion.componentType !== componentType) {
                  return currentVersion;
                }
                return new CurrentSiteVersion({
                  component: componentType,
                  version: currentVersion.desiredVersion,
                  desiredVersion: currentVersion.desiredVersion
                });
              });
            }
          );
        }

        queryClient.removeQueries([SiaCacheKeys.SiteVersions, siteId]);
        return;
      },
      onError: e => {
        throw e;
      }
    }
  );
};

export const useModifyDatabaseMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, SiteDatabaseCommandArgs>(
    ({ siteId, command }) => siaOpsGateway.modifyPrOSDatabase(siteId, command)
  );
};

export const useCheckUpdateMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    siaOpsGateway.checkUpdate(siteId)
  );
};

export const useProsBannerMutation = () => {
  // @TODO  Replace when api ready
  return useMutation<string, Error, string>(() => Promise.resolve(""));
};

export const useInstallPrerequisites = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    siaOpsGateway.installPrerequisites(siteId)
  );
};

export const useDeleteEntityEventsMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, string>(siteId =>
    siaOpsGateway.deleteEntityEvents(siteId)
  );
};

export const useSiteComponentCredentialMutation = () => {
  const { siaOpsGateway } = useGateways();

  return useMutation<string, Error, string>(tenantId =>
    siaOpsGateway.updateSiteComponentCredentials(tenantId)
  );
};

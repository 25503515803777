import { DateTime } from "@bps/utils";
import {
  ConsumerUser,
  ConsumerUserDto
} from "./best-health-account-ops-gateway.dtos";

export const convertIdentityDto = (dto: ConsumerUserDto): ConsumerUser => {
  return {
    ...dto,
    createdDateTime: dto.createdDateTime
      ? DateTime.fromISO(dto.createdDateTime)
      : undefined,
    deletedDateTime: dto.deletedDateTime
      ? DateTime.fromISO(dto.deletedDateTime)
      : undefined,
    lastPasswordChangeDateTime: dto.lastPasswordChangeDateTime
      ? DateTime.fromISO(dto.lastPasswordChangeDateTime)
      : undefined
  };
};

import { guid } from "@libs/common/guid";
import { useMutation, useQuery } from "react-query";

import {
  PowerBiDataset,
  TtmReportingSnapshotOptions
} from "./ttm-reporting-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";
import { useRootStore } from "@stores/StoresProvider";

export const useGetDatasets = () => {
  const { ttmReportingOpsGateway } = useGateways();

  return useQuery<PowerBiDataset[]>([], async () => {
    return await ttmReportingOpsGateway.getDatasets();
  });
};

export const usePostTtmReportSnapshot = (tenantId: guid) => {
  const { ttmReportingOpsGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<string, Error, TtmReportingSnapshotOptions>(
    (options: TtmReportingSnapshotOptions) =>
      ttmReportingOpsGateway.postSnapshot(tenantId, options),
    {
      onSuccess: () => {
        feedback.success("Report data snapshot has been initiated.");
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

export const usePostTtmReportBillingSnapshot = (tenantId: guid) => {
  const { ttmReportingOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<string, Error, TtmReportingSnapshotOptions>(
    (options: TtmReportingSnapshotOptions) =>
      ttmReportingOpsGateway.postBillingSnapshot(tenantId, options),
    {
      onSuccess: () => {
        feedback.success("Report billing data snapshot has been initiated.");
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

export const usePostTtmReportDataIngest = (tenantId: guid, type: string) => {
  const { ttmReportingOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<string, Error, string>(
    () => ttmReportingOpsGateway.postIngestData(tenantId, type),
    {
      onSuccess: () => {
        feedback.success("Report data ingestion has been initiated.");
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

export const usePostDatasetRefresh = (datasetId: guid) => {
  const { ttmReportingOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<string, Error, string>(
    () => ttmReportingOpsGateway.postDatasetRefresh(datasetId),
    {
      onSuccess: () => {
        feedback.success("Dataset refresh has been initiated.");
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

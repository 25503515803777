import { DateTime } from "@bps/utils";
import { ChangeLogDto } from "@libs/api/types/common-dtos";
import { guid } from "@libs/common/guid";
import { RJSFSchema } from "@rjsf/utils";

export interface SoftwarePackageVersionConfig {
  id?: string;
  softwarePackageVersionId: string;
  key: string;
  defaultValue: string;
  description: string;
  dataType: string;
  createdDate?: DateTime;
  createdBy?: string;
}

export interface SoftwarePackageVersionConfigArgs {
  softwarePackageVersionId: string;
}

export interface SecurityRole {
  id: guid;
  code: string;
  isInactive: boolean;
  text: string;
  description: string;
  rolePermissions?: {
    permissionCode: string;
    source: string;
    RoleId: string;
  }[];
  isSystemManaged: boolean;
}

export interface FieldDeviceDetails
  extends Omit<
    FieldDeviceDetailsDto,
    | "controllerCreatedDateTimeUtc"
    | "controllerStartDateTimeUtc"
    | "controllerLastObservedDateTimeUtc"
    | "recoveryAgentCreatedDateTimeUtc"
    | "recoveryAgentStartDateTimeUtc"
    | "recoveryAgentLastObservedDateTimeUtc"
  > {
  controllerCreatedDateTimeUtc: DateTime;
  controllerStartDateTimeUtc: DateTime;
  controllerLastObservedDateTimeUtc: DateTime;
  recoveryAgentCreatedDateTimeUtc: DateTime;
  recoveryAgentStartDateTimeUtc: DateTime;
  recoveryAgentLastObservedDateTimeUtc: DateTime;
}

export interface FieldDeviceDetailsDto {
  id: guid;
  cloudConnectorId: guid;
  etag: string;
  changelog: Changelog;
  computerName: string;
  osName: string;
  osVersion: string;
  systemDomain: string;
  timezone: string;
  maintenanceScriptVersion: string;
  controllerStatus: string;
  controllerRunStatusSid: string;
  controllerVersion: string;
  controllerStartup: string;
  controllerCreatedDateTimeUtc: string;
  controllerStartDateTimeUtc: string;
  controllerLastObservedDateTimeUtc: string;
  recoveryAgentStatus: string;
  recoveryAgentRunStatusSid: string;
  recoveryAgentVersion: string;
  recoveryAgentStartup: string;
  recoveryAgentCreatedDateTimeUtc: string;
  recoveryAgentStartDateTimeUtc: string;
  recoveryAgentLastObservedDateTimeUtc: string;
}

export interface FieldDevice {
  id: guid;
  cloudConnectorId: guid;
  etag: string;
  changeLog: Changelog;
  appRegistrationId?: guid;
  notBeforeTimeUtc?: DateTime;
  tokenExpiryUtc?: DateTime;
  authEnabled?: boolean;
  issueTimeUtc?: DateTime;
}

export interface FieldDeviceDto
  extends Omit<
    FieldDevice,
    "notBeforeTimeUtc" | "tokenExpiryUtc" | "issueTimeUtc" | "changeLog"
  > {
  notBeforeTimeUtc: string;
  tokenExpiryUtc: string;
  issueTimeUtc: string;
  changeLog: ChangeLogDto;
}

export interface FieldDeviceFilterArgs {
  searchTerm: string;
}

export interface Changelog {
  createdDate?: DateTime;
  createdBy?: string;
  updatedDate?: DateTime;
  updatedBy?: string;
}

export interface RolloutPackageDesiredConfig {
  id?: string;
  rolloutPackageId: string;
  key: string;
  value: string;
  eTag?: string;
  changeLog?: Changelog;
}

export interface RolloutPackageDesiredConfigArgs {
  rolloutPackageId: string;
}

export interface RolloutPackageDesiredConfigCreateRequest {
  rolloutPackageId: string;
  key: string;
  value: string;
}

export interface RolloutPackageDesiredConfigUpdateRequest
  extends RolloutPackageDesiredConfigCreateRequest {
  id: string;
  eTag?: string;
  changeLog?: Changelog;
}

export interface RolloutPackage {
  id?: string;
  rolloutId: string;
  softwarePackageVersionId: string;
  eTag?: string;
  changeLog?: Changelog;
}

export interface RolloutPackageArgs {
  rolloutId: string;
}

export interface RolloutPackageCreateRequest {
  rolloutId: string;
  softwarePackageVersionId: string;
}

export interface RolloutPackageUpdateRequest {
  id: string;
  rolloutId: string;
  softwarePackageVersionId: string;
  eTag?: string;
}

export interface Rollout {
  id?: string;
  deploymentRingId: string;
  isActive: boolean;
  displayName?: string;
  initialDownloadStartUtc?: DateTime;
  initialDownloadEndUtc?: DateTime;
  initialInstallStartUtc?: DateTime;
  initialInstallEndUtc?: DateTime;
  eTag?: string;
}

export interface RolloutCreateRequest {
  deploymentRingId: string;
  isActive: boolean;
  displayName?: string;
  initialDownloadStartUtc: string;
  initialDownloadEndUtc: string;
  initialInstallStartUtc: string;
  initialInstallEndUtc: string;
  rolloutId?: string;
}

export interface RolloutUpdateRequest {
  id: string;
  deploymentRingId: string;
  isActive: boolean;
  displayName?: string;
  initialDownloadStartUtc: string;
  initialDownloadEndUtc: string;
  initialInstallStartUtc: string;
  initialInstallEndUtc: string;
  eTag: string;
}

export interface RingMember {
  id: string;
  name: string;
  deviceId: string;
  connectorId: string;
  lastUpdateSuccess: DateTime;
  lastUdpateAttempt: DateTime;
}

export interface FieldService {
  code: string;
  name: string;
  approvedDefaultVersion: string;
  availableBuildVersions: string[];
}

export interface SoftwarePackage {
  id: guid;
  code: string;
  displayName: string;
  description: string;
  softwarePackageType: SoftwarePackageType;
  softwarePublisherId: guid;
  isActive: boolean;
}

export interface SoftwarePackageArgs {
  softwarePublisherCode?: string;
  softwarePackageType?: SoftwarePackageType;
  isActive?: boolean;
}

export enum SoftwarePackageType {
  Service = "SERVICE",
  Module = "MODULE",
  Application = "APPLICATION",
  File = "FILE",
  Bundle = "BUNDLE",
  Dependency = "DEPENDENCY"
}

export enum SoftwarePackageServiceCodes {
  FieldController = "BestPracticeFieldController",
  RecoveryAgent = "BestPracticeFieldRecoveryAgent"
}

export interface FieldDeviceReportedConfig {
  fieldDeviceConfigReportId: string;
  fieldDeviceId: guid;
  softwarePackageType: SoftwarePackageType;
  softwarePackageCode: string;
  softwarePackageDisplayName: string;
  softwarePackageId: guid;
  softwarePackageVersion: string;
  softwarePackageVersionId?: guid;
  softwarePackageIsActive: boolean;
  softwarePublisherId: guid;
  softwarePublisherName: string;
  status: string;
  runStatus: string;
  startup: string;
  user: string;
  path: string;
  startDateTimeUtc: DateTime;
  createdDateTimeUtc: DateTime;
  lastObservedDateTimeUtc: DateTime;
}

export interface FieldDeviceReportedConfigDto
  extends Omit<
    FieldDeviceReportedConfig,
    "createdDateTimeUtc" | "startDateTimeUtc" | "lastObservedDateTimeUtc"
  > {
  startDateTimeUtc: string;
  createdDateTimeUtc: string;
  lastObservedDateTimeUtc: string;
}

export interface FieldDevicePropertyDto {
  id: number;
  fieldDeviceId?: guid;
  propertyType: string;
  propertyValue?: string;
  firstObservedUtc?: string;
  prevObservedUtc?: string;
  observationCount: number;
  lastSourceType?: string;
  lastSourceId?: string;
  lastObservedUtc?: string;
}

export interface FieldDeviceProperty
  extends Omit<
    FieldDevicePropertyDto,
    "firstObservedUtc" | "prevObservedUtc" | "lastObservedUtc"
  > {
  firstObservedUtc?: DateTime;
  prevObservedUtc?: DateTime;
  lastObservedUtc?: DateTime;
}

export interface FieldDeviceIdentifierDto {
  id: number;
  fieldDeviceId?: guid;
  identifierType: string;
  identifierValue?: string;
  firstObservedUtc?: string;
  prevObservedUtc?: string;
  observationCount: number;
  lastSourceType?: string;
  lastSourceId?: string;
  lastObservedUtc?: string;
  authenticated: boolean;
}

export interface FieldDeviceIdentifier
  extends Omit<
    FieldDeviceIdentifierDto,
    "firstObservedUtc" | "prevObservedUtc" | "lastObservedUtc"
  > {
  firstObservedUtc?: DateTime;
  prevObservedUtc?: DateTime;
  lastObservedUtc?: DateTime;
}

export interface FieldDeviceDeploymentRingDetail {
  fieldDeviceId: string;
  id: string;
  deploymentRingId: string;
  name?: string;
  code: string;
  description?: string;
  sortOrder: number;
  isPublic: boolean;
  eTag: string;
}

export interface AddFieldDeviceDeploymentRingRequest {
  deploymentRingId: string;
  fieldDeviceId: string;
  sortOrder: number;
}

export interface DeploymentRingFieldDevice {
  id: string;
  deploymentRingId: string;
  fieldDeviceId: string;
  sortOrder: number;
  eTag: string;
}

export interface FieldDeviceConfiguration {
  cloudConnectorId: guid;
  bundlesToInstall: FieldDeviceSoftwarePackageVersionConfig[];
  componentsToInstall: FieldDeviceComponentConfig[];
  configurationSettings: FieldDeviceConfigSetting[];
  configurationVersion: string;
  maintenance: FieldDeviceSoftwarePackageVersionConfig;
  files: FieldDeviceSoftwarePackageVersionConfig[];
  oneTimeActions: FieldDeviceActionRunnerConfig;
  options: FieldDeviceConfigOption;
}

export interface FieldDeviceComponentConfig
  extends FieldDeviceDateRangeSoftwarePackageVersionConfig {
  modules?: {
    [code: string]: FieldDeviceDateRangeSoftwarePackageVersionConfig;
  };
  mustBeInstalledBy?: string;
  updatesFieldComponent?: string;
}

export interface FieldDeviceDateRangeSoftwarePackageVersionConfig
  extends FieldDeviceSoftwarePackageVersionConfig {
  downloadEndUtc?: DateTime;
  downloadStartUtc?: DateTime;
  installEndUtc?: DateTime;
  installStartUtc?: DateTime;
}

export interface FieldDeviceSoftwarePackageVersionConfig {
  id: guid;
  code: string;
  dependencies: { [id: number]: guid };
  dependencyCodes: { [id: number]: string };
  downloadUrl: string;
  executableName?: string;
  expectedThumbprint?: string;
  isZipped: boolean;
  launchArguments?: string;
  maximumWindowsVersion?: string;
  minimumWindowsVersion?: string;
  name: string;
  type?: SoftwarePackageType;
  version: string;
}

export interface FieldDeviceConfigSetting {
  dataType: string;
  key: string;
  softwarePackageCode: string;
  value?: string;
}

export interface FieldDeviceActionRunnerConfig {
  oneTimeActions: FieldDeviceConfigurationOneTimeAction[];
}

export interface FieldDeviceConfigurationOneTimeAction {
  arguments: string;
  toBeRunBy: string;
  cannotBeRunAfterUtc?: DateTime;
  cannotBeRunBeforeUtc?: DateTime;
  id: number;
  maximumWindowsVersion?: string;
  minimumWindowsVersion?: string;
}

export interface FieldDeviceConfigOption {
  applicationInsightsConnectionString: string;
  fieldGatewayApiBaseUrl: string;
  fieldGatewayEndpoint: string;
  fieldManagementApiBaseUrl: string;
}

export interface FieldDeviceAuthAction {
  id: string;
  fieldDeviceId: string;
  deviceAction: string;
  deviceIdentifierType: string;
  deviceIdentifierValue: string;
  runDate?: DateTime;
  eTag: string;
  changeLog: Changelog;
}

export type FieldDeviceAuthActionResponse = Omit<
  FieldDeviceAuthAction,
  "changeLog" | "runDate"
> & {
  changeLog: ChangeLogDto;
  runDate?: string;
};

export interface FieldDeviceAuthActionRequest {
  fieldDeviceId: string;
  deviceAction: string;
  deviceIdentifierType: string;
  deviceIdentifierValue: string;
}

export interface DeviceConfigurationDiff {
  desiredConfig: DiffSoftwarePackage[];
  reportedConfig: DiffSoftwarePackage[];
}

export interface DiffSoftwarePackage {
  softwarePackageCode: string;
  version: string;
  settings: DiffSoftwarePackageConfigurationSetting[];
}

export interface DiffSoftwarePackageConfigurationSetting {
  key: string;
  value: string;
}

export interface DeploymentRing {
  id: guid;
  code: string;
  isInactive: string;
  isPublic: boolean;
  displayName: string;
  description: string;
  selectorId?: guid;
  changeLog: Changelog;
  eTag?: string;
}

export interface DeploymentRingCreateRequest {
  code: string;
  isPublic: boolean;
  displayName: string;
  description: string;
}

export interface DeploymentRingUpdateRequest {
  code: string;
  isPublic: boolean;
  displayName: string;
  description: string;
  deploymentRingId: guid;
  selectorId?: guid;
  eTag: string;
}

export interface CustomRJSFSchema extends RJSFSchema {
  "x-messageType": string;
}

export enum ChannelPreference {
  Queued = "Queued",
  Realtime = "Realtime",
  RealtimeWithQueuedFallback = "RealtimeWithQueuedFallback"
}

export interface DeviceCommand {
  fieldDeviceId: string;
  channelPreference?: string;
  targetSoftwarePackageCode: string;
  routedMessageData: RoutedMessageData;
}

export interface RoutedMessageData {
  messageType: string;
  value: string;
}

export interface CommandResponse {
  Status?: number;
  Value?: string;
  ValueType?: string;
  ErrorMessage?: string;
}

export interface SelectorArgs {
  selectorId?: string;
  name?: string;
}

export interface SelectorCreateRequest {
  name: string;
  description: string;
  rules: SelectorCreateRequestRule[];
}

export interface SelectorCreateRequestRule {
  attributeType?: string;
  valueDataType?: string;
  comparisonOperator?: string;
  value?: string;
}

export type SelectorRuleDto = {
  id?: guid;
  selectorId?: guid;
  attributeType?: string;
  valueDataType?: string;
  comparisonOperator?: string;
  value?: string;
  etag?: string;
  changeLog?: ChangeLogDto;
};
export type SelectorDto = {
  id: guid;
  name: string;
  description?: string;
  deviceCount: string;
  etag?: string;
  changeLog?: ChangeLogDto;
  rules: SelectorRuleDto[];
};

export interface SoftwarePackageDefault {
  id: guid;
  softwarePackageId: guid;
  softwarePackageVersionId: guid;
  changeLog: Changelog;
  eTag?: string;
}

export interface SoftwarePackageDefaultRequest {
  softwarePackageId: guid;
  softwarePackageVersionId: guid;
}

export interface SoftwarePackageVersion {
  id: guid;
  certificateThumbprint: string;
  datePublishedUtc: DateTime;
  executableName: string;
  launchArguments: string;
  maximumWindowsVersion: string;
  minimumWindowsVersion: string;
  softwarePackageId: guid;
  url: string;
  version: string;
  virusScanIssueCount: number;
}

export interface SoftwarePackageVersionArgs {
  datePublishedFrom?: string;
  datePublishedTo?: string;
  softwarePackageId?: string;
  softwarePublisherId?: string;
  softwarePackageType?: string;
  version?: string;
}

export interface SoftwarePublisher {
  id: guid;
  code: string;
  description: string;
  displayName: string;
}

export interface CombinedSoftwarePackage {
  id: guid;
  softwarePackageCode: string;
  softwarePackageType: SoftwarePackageType;
  currentVersion?: string;
  desiredVersion?: string;
  name: string;
}

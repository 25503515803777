import { AxiosInstance } from "@bps/http-client";

import {
  PowerBiDataset,
  TtmReportingSnapshotOptions
} from "./ttm-reporting-ops-gateway.dtos";
import { ITtmReportingOpsGateway } from "./ttm-reporting-ops-gateway";

export class TtmReportingOpsGateway implements ITtmReportingOpsGateway {
  constructor(private api: AxiosInstance) {}

  async postSnapshot(
    tenantId: string,
    options?: TtmReportingSnapshotOptions
  ): Promise<void> {
    const url = `tenants/${tenantId}/snapshot`;
    await this.api.post(url, options);
  }

  async postBillingSnapshot(
    tenantId: string,
    options?: TtmReportingSnapshotOptions
  ): Promise<void> {
    const url = `tenants/${tenantId}/snapshot/billing`;
    await this.api.post(url, options);
  }

  async postIngestData(
    tenantId: string,
    type: string,
    options?: TtmReportingSnapshotOptions
  ): Promise<void> {
    const url = `tenants/${tenantId}/snapshot/ingest/${type}`;
    await this.api.post(url, options);
  }

  async getDatasets(): Promise<PowerBiDataset[]> {
    const { data } = await this.api.get<PowerBiDataset[]>("datasets");
    return data;
  }

  async postDatasetRefresh(datasetId: string): Promise<void> {
    const url = `datasets/refresh/${datasetId}`;
    await this.api.post(url);
  }
}

import { useRootStore } from "@stores/StoresProvider";
import { guid } from "@libs/common/guid";
import { useMutation } from "react-query";

import { TtmFormsRefreshOptions } from "./ttm-forms-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

export const usePostTtmFormsRefresh = (tenantId: guid) => {
  const { ttmFormsOpsGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<string, Error, TtmFormsRefreshOptions>(
    (options: TtmFormsRefreshOptions) =>
      ttmFormsOpsGateway.requestRefresh(tenantId, options),
    {
      onSuccess: () => {
        feedback.success("Forms design refresh has been initiated.");
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

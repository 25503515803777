import { useState } from "react";
import { useDebounce } from "react-use";

/**
 * Simple hook that provides debounced updates to a value.
 *
 * @param liveValue The current 'live' value
 * @param delayMillis Debounce delay in milliseconds. Default is 1000.
 */
export const useDebouncedValue = <T extends unknown>(
  liveValue: T,
  delayMillis: number = 1000
): T => {
  const [value, setValue] = useState<T>(liveValue);
  useDebounce(() => setValue(liveValue), delayMillis, [liveValue]);

  return value;
};

import { DateTime } from "@bps/utils";
import {
  EntitySequence,
  EntitySnapshot
} from "@libs/api/gateways/tg/tg-ops-gateway.dtos";

export interface EntitySyncStateDto {
  entitySequences: EntitySequenceDto[];
  entitySnapshots: EntitySnapshotDto[];
}

export interface EntitySequenceDto extends Omit<EntitySequence, "updatedDate"> {
  updatedDate?: string;
}

export interface EntitySnapshotDto
  extends Omit<EntitySnapshot, "snapshotActive" | "processingStarted"> {
  snapshotActiveUtc?: string;
  processingStartedUtc?: string;
}

export class EntitySyncState {
  entitySequences: EntitySequence[];
  entitySnapshots: EntitySnapshot[];

  constructor(dto: EntitySyncStateDto) {
    this.entitySequences =
      dto.entitySequences?.map(es => ({
        ...es,
        updatedDate: es.updatedDate
          ? DateTime.fromISO(es.updatedDate)
          : undefined
      })) ?? [];

    this.entitySnapshots =
      dto.entitySnapshots?.map(es => ({
        ...es,
        snapshotActive: es.snapshotActiveUtc
          ? DateTime.fromISO(es.snapshotActiveUtc)
          : undefined,
        processingStarted: es.processingStartedUtc
          ? DateTime.fromISO(es.processingStartedUtc)
          : undefined
      })) ?? [];
  }
}

import { Permissions } from "@libs/permissions/permissions.enum";
import { PermissionsOperator } from "@libs/permissions/permissions-operator.type";

export const verifyHasPermissions = (
  userPermissions: Permissions[] = [],
  requiredPermissions: Permissions | Permissions[] = [],
  operator: PermissionsOperator = "and"
): boolean => {
  // No permissions required
  if (!requiredPermissions) return true;
  if (Array.isArray(requiredPermissions) && !requiredPermissions.length)
    return true;

  // Permissions required but the user doesn"t have any permissions
  if (!userPermissions?.length) return false;

  const requiredPermissionsArray = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions];
  return operator === "or"
    ? requiredPermissionsArray!.some(p => userPermissions.includes(p))
    : requiredPermissionsArray!.every(p => userPermissions.includes(p));
};

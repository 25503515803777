export type guid = string;
type NullableGuid = guid | null | undefined;

export class Guid {
  public static readonly empty = "00000000-0000-0000-0000-000000000000";
  public static isGuid = (value: NullableGuid) => {
    if (value == null || value.length < 32) return false;

    const naked = this.toNaked(value);
    return naked!.length === 32 && /^[\da-f]+$/i.test(naked!);
  };

  public static toNaked = (id: guid) =>
    id ? id.replace(/[{}-]/g, "").toLowerCase() : null;

  public static areEqual = (left: guid, right: guid) =>
    this.toNaked(left) === this.toNaked(right);

  public static isNullOrEmpty = (id: guid): boolean => {
    return id == null || !/[^0]/.test(this.toNaked(id)!);
  };
}

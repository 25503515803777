import { DateTime } from "@bps/utils";

import { OutboundSmsAuditDto } from "../plt-comms-gateway.dtos";

export class OutboundSmsAudit {
  private dto: OutboundSmsAuditDto;
  constructor(dto: OutboundSmsAuditDto) {
    this.dto = dto;
  }

  get id() {
    return this.dto.id;
  }

  get outboundSmsId() {
    return this.dto.outboundSmsId;
  }

  get content() {
    return this.dto.content;
  }

  get vendorCode() {
    return this.dto.vendorCode;
  }

  get outboundSmsAuditType() {
    return this.dto.outboundSmsAuditType;
  }

  get createdDate(): DateTime | undefined {
    return this.dto.changeLog.createdDate
      ? DateTime.fromISO(this.dto.changeLog.createdDate)
      : undefined;
  }
}

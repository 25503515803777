import { DateTime } from "@bps/utils";

import { Tenant, TenantDto } from "./plt-gateway.dtos";

export const toTenant = (dto: TenantDto): Tenant => {
  const { createdDate, updatedDate, ...changeLogRest } = dto.changeLog;

  return {
    ...dto,
    changeLog: {
      ...changeLogRest,
      createdDate: DateTime.fromISO(createdDate),
      updatedDate: updatedDate ? DateTime.fromISO(updatedDate) : undefined
    }
  };
};

export const toTenantDto = (dto: Tenant): TenantDto => {
  return {
    ...dto,
    changeLog: {
      ...dto.changeLog,
      createdDate: dto.changeLog.createdDate.toISO(),
      updatedDate: dto.changeLog.updatedDate
        ? dto.changeLog.updatedDate?.toISO()
        : undefined
    }
  };
};

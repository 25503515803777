import { formatISO } from "date-fns"; // Make sure to install date-fns if not already installed
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BpMobileMaintenanceSettings } from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";
import { useGateways } from "@libs/api/gateways-context";

const CacheKeys = {
  BpMobileAppSettings: "bpMobileAppSettings"
};

export const useGetBpMobileAppSettings = () => {
  const { bpMobileOpsGateway } = useGateways();
  return useQuery<BpMobileMaintenanceSettings, Error>(
    CacheKeys.BpMobileAppSettings,
    () => bpMobileOpsGateway.getMobileAppSettings()
  );
};

export const usePutBpMobileAppSettings = () => {
  const { bpMobileOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: BpMobileMaintenanceSettings) => {
      // Add the current UTC date to the updates object
      const updatesWithDate = {
        ...updates,
        dateUtc: formatISO(new Date(), { representation: "complete" })
      };

      return bpMobileOpsGateway.putMobileAppSettings(updatesWithDate);
    },
    {
      onSuccess: response => {
        queryClient.setQueryData(CacheKeys.BpMobileAppSettings, response);
      },
      onError: (error: Error) => {
        throw error;
      }
    }
  );
};

import { guid } from "@libs/common/guid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AppointmentStats,
  EntitySequence,
  LocationAppointmentStats,
  PracticeLocation,
  PracticeLocationUpdate,
  Provider
} from "./best-health-ops-gateway.dtos";
import { useGateways } from "@libs/api/gateways-context";

const BestHealthCacheKeys = {
  AppointmentStats: "bh-appointment-stats",
  AllAppointmentStats: "bh-appointment-stats-all",
  LocationAppointmentStats: "bh-location-appointment-stats",
  PracticeLocations: "bh-practice-locations",
  BhbProviders: "bhb-providers",
  SyncState: "bh-sync-state",
  PracticeOutage: "bhb-practice-outage"
};

export const usePracticeLocationsQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<PracticeLocation[]>(
    [BestHealthCacheKeys.PracticeLocations, tenantId],
    async () => {
      return await bestHealthOpsGateway.getPracticeLocations(tenantId);
    }
  );
};

export const useAppointmentStatsQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<AppointmentStats>(
    [BestHealthCacheKeys.AppointmentStats, tenantId],
    async () => {
      return await bestHealthOpsGateway.getAppointmentStats(tenantId);
    }
  );
};

export const useLocationAppointmentStatsQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<LocationAppointmentStats[]>(
    [BestHealthCacheKeys.LocationAppointmentStats, tenantId],
    async () => {
      return await bestHealthOpsGateway.getLocationAppointmentStats(tenantId);
    }
  );
};

export const useAllAppointmentStatsQuery = () => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<AppointmentStats[]>(
    [BestHealthCacheKeys.AllAppointmentStats],
    async () => {
      return await bestHealthOpsGateway.getAllAppointmentStats();
    }
  );
};

export const useBhbEntitySyncStateQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<EntitySequence[]>(
    [BestHealthCacheKeys.SyncState, tenantId],
    async () => {
      return await bestHealthOpsGateway.getSyncState(tenantId);
    }
  );
};

export const useGetPracticeOutageQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useQuery<boolean>(
    [BestHealthCacheKeys.PracticeOutage, tenantId],
    async () => {
      return await bestHealthOpsGateway.getPracticeOutage(tenantId);
    }
  );
};

export interface PutPracticeMutationArgs {
  practiceOutage: boolean;
}

export const usePutPracticeOutageQuery = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<boolean, Error, PutPracticeMutationArgs>(
    ({ practiceOutage }) =>
      bestHealthOpsGateway.putPracticeOutage(tenantId, practiceOutage),
    {
      onSuccess: async (_, { practiceOutage }: PutPracticeMutationArgs) => {
        queryClient.setQueryData(
          [BestHealthCacheKeys.PracticeOutage, practiceOutage],
          {
            practiceOutage
          }
        );
      }
    }
  );
};

export const useGetProviders = (tenantId: string) => {
  const { bestHealthOpsGateway } = useGateways();
  return useQuery<Provider[]>(
    [BestHealthCacheKeys.BhbProviders, tenantId],
    async () => {
      return await bestHealthOpsGateway.getProviders(tenantId);
    }
  );
};

export interface PutLocationMutationArgs {
  locationId: string;
  updates: PracticeLocationUpdate;
}

export const usePutLocation = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<PracticeLocation, Error, PutLocationMutationArgs>(
    ({ locationId, updates }) =>
      bestHealthOpsGateway.putPracticeLocation(locationId, updates),
    {
      onSuccess: async (response, { locationId }: PutLocationMutationArgs) => {
        queryClient.setQueriesData<PracticeLocation[]>(
          [BestHealthCacheKeys.PracticeLocations, tenantId],
          (data: PracticeLocation[]) => {
            const newData = [...data];
            const i = newData.findIndex(location => location.id === locationId);
            if (i && newData[i]) {
              newData[i] = {
                ...newData[i],
                ...response
              };
            }

            return newData;
          }
        );
      }
    }
  );
};

export const usePostTtmSnapshot = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useMutation<string, Error, string>(() =>
    bestHealthOpsGateway.postTtmSnapshot(tenantId)
  );
};
export const usePostTtmRecalculate = (tenantId: guid) => {
  const { bestHealthOpsGateway } = useGateways();

  return useMutation<string, Error, string>(() =>
    bestHealthOpsGateway.postRecalculate(tenantId)
  );
};

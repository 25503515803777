import { AxiosError } from "axios";
import { useRootStore } from "@stores/StoresProvider";
import { cacheUpsertSingleItemInArray } from "@libs/react-query/react-query-cache.utils";
import {
  ApplicationCreateRequest,
  ApplicationUpdateRequest,
  CallbackCreateRequest,
  CallbackDto,
  CallbackTypesDto,
  CallbackTypesUpdateRequest,
  CallbackUpdateRequest,
  CertificateCreateRequest,
  CertificateDto,
  CertificateIdentifier,
  ConsentCreateRequest,
  ConsentPatchRequest,
  ConsentUpdateRequest,
  TenantConsentArgs,
  TenantSettingDto,
  TenantSettingUpdateRequest
} from "@libs/api/gateways/plt-integrators/plt-integrators-gateway.dtos";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { OrgUnitArgs, OrgUnitDto } from "../plt/plt-gateway.dtos";
import {
  ApplicationArgs,
  ApplicationCloudApplicationDto,
  ApplicationDto,
  CloudApplicationScopeDto,
  CloudApplicationScopeUpdateRequest,
  CloudApplicationSecretCreateRequest,
  CloudApplicationSecretDeleteRequest,
  CloudApplicationSecretDto,
  ConsentDto
} from "./plt-integrators-gateway.dtos";
import { HttpError } from "@bps/http-client";
import { useGateways } from "@libs/api/gateways-context";

export const PltIntegratorsCacheKeys = {
  Applications: "plt-integ-applications",
  Application: "plt-integ-application",
  GatewayScopes: "plt-integ-gateway-scopes",
  callbackTypes: "callback-types",
  Certificates: "plt-integ-certificates",
  Certificate: "plt-integ-certificate",
  TenantSetting: "plt-integ-tenant-setting",
  Consent: "plt-integ-tenant-consent",
  Callback: "plt-integ-tenant-callback"
};

export const useApplicationsQuery = (tenantId: string) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<ApplicationDto[], AxiosError>(
    [PltIntegratorsCacheKeys.Applications, tenantId],
    async () => {
      const applications = await platformIntegratorsGateway.getApplications(
        tenantId
      );
      return applications.sort((left, right) =>
        left.changeLog!.createdDate!.localeCompare(
          right.changeLog!.createdDate!
        )
      );
    }
  );
};

export const useApplicationQuery = (args: ApplicationArgs) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<ApplicationCloudApplicationDto, AxiosError>(
    [PltIntegratorsCacheKeys.Application, args],
    async () => {
      return await platformIntegratorsGateway.getApplication(args);
    }
  );
};

export const useGatewayScopesQuery = () => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<CloudApplicationScopeDto[], AxiosError>(
    [PltIntegratorsCacheKeys.GatewayScopes],
    async () => {
      return await platformIntegratorsGateway.getGatewayScopes();
    }
  );
};

export const useUpdateApplicationScopes = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<string[], HttpError, CloudApplicationScopeUpdateRequest>(
    platformIntegratorsGateway.updateApplicationScopes,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Application,
          { applicationId: request.applicationId, tenantId: request.tenantId }
        ]);
        feedback.success("Scopes have been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useCreateApplicationSecret = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    CloudApplicationSecretDto,
    HttpError,
    CloudApplicationSecretCreateRequest
  >(platformIntegratorsGateway.createApplicationSecret, {
    onSuccess: async (dto, request) => {
      queryClient.invalidateQueries([
        PltIntegratorsCacheKeys.Application,
        { applicationId: request.applicationId, tenantId: request.tenantId }
      ]);
      feedback.success("Secret has been created");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useDeleteApplicationSecret = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, CloudApplicationSecretDeleteRequest>(
    platformIntegratorsGateway.deleteApplicationSecret,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Application,
          { applicationId: request.applicationId, tenantId: request.tenantId }
        ]);
        feedback.success("Secret has been deleted");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useCreateApplication = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ApplicationDto, HttpError, ApplicationCreateRequest>(
    platformIntegratorsGateway.createApplication,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          [PltIntegratorsCacheKeys.Applications, request.tenantId]
        ]);
        feedback.success("Application has been created");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdateApplication = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ApplicationDto, HttpError, ApplicationUpdateRequest>(
    platformIntegratorsGateway.patchApplication,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Applications,
          request.tenantId
        ]);
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Application,
          { applicationId: request.applicationId, tenantId: request.tenantId }
        ]);
        feedback.success("Application has been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

//Callback Type
export const useCallbackTypes = () => {
  const { platformIntegratorsGateway } = useGateways();
  return useQuery<CallbackTypesDto[], AxiosError>(
    [PltIntegratorsCacheKeys.callbackTypes],
    async () => {
      return await platformIntegratorsGateway.getCallbackTypes();
    }
  );
};

export const useCallbackTypesQuery = () => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<CallbackTypesDto[], AxiosError>(
    [PltIntegratorsCacheKeys.callbackTypes],
    async () => {
      const callbackTypes = await platformIntegratorsGateway.getCallbackTypes();
      return callbackTypes.sort((left, right) =>
        left.changeLog!.createdDate!.localeCompare(
          right.changeLog!.createdDate!
        )
      );
    }
  );
};

export const useUpdateCallbackType = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<CallbackTypesDto, HttpError, CallbackTypesUpdateRequest>(
    platformIntegratorsGateway.patchCallbackTypes,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.callbackTypes,
          { code: request.code }
        ]);
        feedback.success("CallbackType has been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useCertificatesQuery = (tenantId: string) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<CertificateDto[], AxiosError>(
    [PltIntegratorsCacheKeys.Certificates, tenantId],
    async () => {
      const certificates = await platformIntegratorsGateway.getCertificates(
        tenantId
      );
      return certificates.sort((left, right) =>
        left.changeLog!.createdDate!.localeCompare(
          right.changeLog!.createdDate!
        )
      );
    }
  );
};

export const useCertificateQuery = (request: CertificateIdentifier) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<CertificateDto, AxiosError>(
    [PltIntegratorsCacheKeys.Certificate, request],
    async () => {
      return await platformIntegratorsGateway.getCertificate(request);
    }
  );
};

export const useCreateCertificate = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<CertificateDto, HttpError, CertificateCreateRequest>(
    platformIntegratorsGateway.createCertificate,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificates,
          request.tenantId
        ]);
        feedback.success("Certificate has been created");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdateCertificate = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<CertificateDto, Error, CertificateCreateRequest>(
    platformIntegratorsGateway.updateCertificate,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificate,
          request
        ]);
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificates,
          request.tenantId
        ]);
        feedback.success("Certificate has been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useDeleteCertificate = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, CertificateIdentifier>(
    platformIntegratorsGateway.deleteCertificate,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificate,
          request
        ]);
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificates,
          request.tenantId
        ]);
        feedback.success("Certificate has been deleted");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useDeployCertificateToGateway = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, CertificateIdentifier>(
    platformIntegratorsGateway.deployCertificateToGateway,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificate,
          request
        ]);
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificates,
          request.tenantId
        ]);
        feedback.success("Certificate has been deployed to gateway");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useRemoveCertificateFromGateway = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, CertificateIdentifier>(
    platformIntegratorsGateway.removeCertificateFromGateway,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificate,
          request
        ]);
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.Certificates,
          request.tenantId
        ]);
        feedback.success("Certificate has been removed from gateway");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdateTenantSetting = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<TenantSettingDto, HttpError, TenantSettingUpdateRequest>(
    platformIntegratorsGateway.updateTenantSetting,
    {
      onSuccess: async (dto, request) => {
        queryClient.invalidateQueries([
          PltIntegratorsCacheKeys.TenantSetting,
          request.tenantId
        ]);
        feedback.success("Tenant setting has been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useTenantSettingQuery = (tenantId: string) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<TenantSettingDto, AxiosError>(
    [PltIntegratorsCacheKeys.TenantSetting, tenantId],
    async () => {
      return await platformIntegratorsGateway.getTenantSetting(tenantId);
    }
  );
};

export const useOrgUnitsQuery = (args: OrgUnitArgs) => {
  const { platformGateway } = useGateways();

  return useQuery<OrgUnitDto[], AxiosError>(
    [PltIntegratorsCacheKeys.Consent],
    async () => {
      return await platformGateway.getOrgUnits(args);
    }
  );
};

export const useConsentsQuery = (tenantId: string) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<ConsentDto[], AxiosError>(
    [PltIntegratorsCacheKeys.Consent, tenantId],
    async () => {
      const consents = await platformIntegratorsGateway.getConsents(tenantId);
      return consents.sort((left, right) =>
        left.changeLog!.createdDate!.localeCompare(
          right.changeLog!.createdDate!
        )
      );
    }
  );
};

export const useConsentQuery = (args: TenantConsentArgs) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<ConsentDto, AxiosError>(
    [PltIntegratorsCacheKeys.Consent, args],
    () => {
      return platformIntegratorsGateway.getConsent(args);
    }
  );
};

export const useCreateConsent = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ConsentDto, HttpError, ConsentCreateRequest>(
    platformIntegratorsGateway.createTenantConsent,
    {
      onSuccess: async (dto, request) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltIntegratorsCacheKeys.Consent, request.tenantId],
          item: dto
        });
        feedback.success("Tenant Consent has been created");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdateConsent = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ConsentDto, HttpError, ConsentUpdateRequest>(
    platformIntegratorsGateway.updateTenantConsent,
    {
      onSuccess: async (dto, request) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltIntegratorsCacheKeys.Consent, request.tenantId],
          item: dto
        });
        feedback.success("Tenant Consent has been created");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const usePatchConsentLocation = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    ConsentDto,
    HttpError,
    ConsentPatchRequest,
    TenantConsentArgs
  >(platformIntegratorsGateway.patchTenantConsentLocation, {
    onSuccess: (dto, request) => {
      cacheUpsertSingleItemInArray({
        queryClient,
        queryKey: [PltIntegratorsCacheKeys.Consent, request.tenantId],
        item: dto
      });
      feedback.success("Location has been removed");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useCallbacksQuery = (tenantId: string) => {
  const { platformIntegratorsGateway } = useGateways();

  return useQuery<CallbackDto[], AxiosError>(
    [PltIntegratorsCacheKeys.Callback, tenantId],
    async () => {
      const callbacks = await platformIntegratorsGateway.getCallbacks(tenantId);
      return callbacks.sort((left, right) =>
        left.changeLog!.createdDate!.localeCompare(
          right.changeLog!.createdDate!
        )
      );
    }
  );
};

export const useCreateCallback = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<CallbackDto, HttpError, CallbackCreateRequest>(
    platformIntegratorsGateway.createCallback,
    {
      onSuccess: async (dto, request) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltIntegratorsCacheKeys.Callback, request.tenantId],
          item: dto
        });
        feedback.success("Callback has been created");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdateCallback = () => {
  const { platformIntegratorsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<CallbackDto, HttpError, CallbackUpdateRequest>(
    platformIntegratorsGateway.updateCallback,
    {
      onSuccess: async (dto, request) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltIntegratorsCacheKeys.Callback, request.tenantId],
          item: dto
        });
        feedback.success("Callback has been updated");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

import { SiteComponentType } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

import { siteComponents } from "@libs/api/gateways/sia/sia-ops-gateway.utils";

export interface CurrentSiteVersionDto {
  component: SiteComponentType;
  version: string | null;
  desiredVersion: string | null;
}

export class CurrentSiteVersion {
  componentType: SiteComponentType;
  displayText: string | undefined;
  version: string | null;
  desiredVersion: string | null;

  constructor(dto: CurrentSiteVersionDto) {
    const siteComponent = siteComponents.find(sc => sc.type === dto.component);
    if (!siteComponent) {
      throw new Error(`Unknown site component: ${dto.component}`);
    }

    this.componentType = siteComponent.type;
    this.displayText = siteComponent.name;
    this.version = dto.version ?? null;
    this.desiredVersion = dto.desiredVersion ?? null;
  }
}

import { IDropdownOption } from "@bps/fluent-ui";

import { SiteComponentVersionDto } from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

export class SiteComponentVersion {
  component: string;
  versions: IDropdownOption[];

  constructor(dto: SiteComponentVersionDto) {
    this.component = dto.component;
    const dropDownOptions: IDropdownOption[] = [];
    if (dto.versions !== undefined) {
      dto.versions.forEach(v => {
        dropDownOptions.push({ key: v.version, text: v.version });
      });
    }
    this.versions = dropDownOptions;
  }
}

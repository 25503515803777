import { IMiddleware, InternalAxiosRequestConfig } from "@bps/http-client";
import { CORRELATION_BAGGAGE } from "../types/headers.constants";
import { Baggage } from "./baggage.type";

export class CorrelationBaggageMiddleware
  implements IMiddleware<InternalAxiosRequestConfig> {
  onFulfilled = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const location = window.location;

    if (location) {
      const baggage: Baggage = {
        path: location.pathname
      };

      if (baggage && config.headers) {
        const encoded = btoa(JSON.stringify(baggage));
        config.headers[CORRELATION_BAGGAGE] = encoded;
      }
    }

    return config;
  };
}

import { HttpError } from "@bps/http-client";

const getHttpErrorMessage = (error: HttpError) => {
  return `${error.message} (${error.httpStatusCode}): ${error.detail}`;
};

export const getErrorAlertMessage = (error: unknown): string | undefined => {
  if (!error) return undefined;

  if (error instanceof HttpError) {
    if (Array.isArray(error)) {
      return error.map(e => getHttpErrorMessage(e)).join(",");
    }

    return getHttpErrorMessage(error) ?? "The data could not be loaded.";
  }

  return String(error);
};

import { ChangeLogDto } from "../../types/common-dtos";

export interface NotificationDto {
  id: string;
  tenantId: string;
  userId: string;
  title: string;
  description: string | undefined;
  type: NotificationType;
  priority: NotificationPriority;
  readDateTime?: string;
  changeLog: ChangeLogDto;
  eTag: string;
}

export type AddNotificationDto = Omit<
  NotificationDto,
  "id" | "eTag" | "changeLog"
>;

export enum NotificationType {
  System = "System"
}

export enum NotificationPriority {
  Low = "Low",
  Normal = "Normal",
  High = "High"
}

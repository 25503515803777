import { AxiosInstance } from "@bps/http-client";
import { stringify } from "query-string";

import { DateTime } from "@bps/utils";

import {
  AppointmentReminderJob,
  AppointmentReminderJobDto,
  AppointmentReminderJobRun,
  AppointmentReminderJobRunDto,
  BookingTenantSettings,
  BookingTenantSettingsDto,
  CalendarEventReminderSearchArgs,
  CalendarEventReminderSearchArgsDto,
  CalendarEventReminderSearchDto
} from "./ttm-booking-ops-gateway.dtos";
import { ITtmBookingOpsGateway } from "@libs/api/gateways/ttm/booking/ttm-booking-ops-gateway.interface";

export class TtmBookingOpsGateway implements ITtmBookingOpsGateway {
  constructor(private api: AxiosInstance) {}

  async getTenantSettings(tenantId: string): Promise<BookingTenantSettings> {
    const url = `tenants/${tenantId}/settings/`;
    const { data } = await this.api.get<BookingTenantSettingsDto>(url);
    const { changeLog, ...dto } = data;

    return {
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    };
  }

  async getAppointmentReminderJobs(
    tenantId: string
  ): Promise<AppointmentReminderJob[]> {
    const url = `tenants/${tenantId}/appointmentReminderJobs/`;
    const { data } = await this.api.get<AppointmentReminderJobDto[]>(url);

    return data.map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }));
  }

  async getAppointmentReminderJobRuns(
    tenantId: string,
    jobId: string
  ): Promise<AppointmentReminderJobRun[]> {
    const url = `tenants/${tenantId}/appointmentReminderJobs/${jobId}/runSummaries`;
    const { data } = await this.api.get<AppointmentReminderJobRunDto[]>(url);

    return data.map(({ changeLog, ...dto }) => ({
      ...dto,
      changeLog: {
        ...changeLog,
        createdDate: changeLog?.createdDate
          ? DateTime.fromISO(changeLog?.createdDate)
          : undefined,
        updatedDate: changeLog?.updatedDate
          ? DateTime.fromISO(changeLog?.updatedDate)
          : undefined
      }
    }));
  }

  async getCalendarEventRemindersBySearchArgs(
    tenantId: string,
    searchArgs: CalendarEventReminderSearchArgs
  ): Promise<CalendarEventReminderSearchDto[]> {
    const searchArgsDto: CalendarEventReminderSearchArgsDto = {
      ...searchArgs,
      startDateTime: searchArgs?.startDateTime
        ? DateTime.fromJSDate(searchArgs?.startDateTime).toISO()
        : undefined,
      endDateTime: searchArgs?.endDateTime
        ? DateTime.fromJSDate(searchArgs?.endDateTime)
            .endOf("day")
            .toISO()
        : undefined
    };

    const url = `tenants/${tenantId}/calendarevents/reminders?${stringify(
      searchArgsDto
    )}`;
    return this.api
      .get<CalendarEventReminderSearchDto[]>(url)
      .then(x => x.data);
  }
}

import {
  SiteComponent,
  SiteComponentType
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";

export const siteComponents: SiteComponent[] = [
  { type: SiteComponentType.SiteAgent, name: "Site Agent" },
  { type: SiteComponentType.SiteManager, name: "Site Manager" }
];
export const getShortComponentTypeName = (component: SiteComponentType) => {
  switch (component) {
    case SiteComponentType.SiteAgent:
      return "agent";
    case SiteComponentType.SiteManager:
      return "manager";
    default:
      throw new Error(`Unsupported site component type: ${component}`);
  }
};
